.joyride-beacon {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500;
}

.joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
  outline: none;
}

.joyride-beacon__inner {
  animation: joyride-beacon-inner 1.2s infinite ease-in-out;
  background-color: #f04;
  border-radius: 50%;
  display: block;
  height: 50%;
  left: 50%;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.joyride-beacon__outer {
  animation: joyride-beacon-outer 1.2s infinite ease-in-out;
  background-color: rgba(255, 0, 68, 0.2);
  border: 2px solid #f04;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  transform-origin: center;
  width: 100%;
}

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500;
}

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute;
}

.joyride-hole.safari {
  box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
}

.joyride-rel-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.joyride-rel-hole.safari {
  box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
}

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510;
}

.joyride-tooltip--animate {
  animation: joyride-tooltip 0.4s forwards;
  animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
}

.joyride-tooltip__triangle {
  background-repeat: no-repeat;
  position: absolute;
}

.joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
  margin-top: 18px;
}

.joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 18px;
  left: 50%;
  top: -16px;
  transform: translateX(-50%);
  width: 36px;
}

.joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
  margin-bottom: 18px;
}

.joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  bottom: -16px;
  height: 18px;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
}

.joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
  left: 3%;
  transform: translateX(0);
}

@media screen and (min-width: 480px) {
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 2%;
  }
}
.joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
  left: auto;
  right: 3%;
  transform: translateX(0);
}

@media screen and (min-width: 480px) {
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    right: 2%;
  }
}
.joyride-tooltip.left {
  margin-right: 18px;
}

.joyride-tooltip.left .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 36px;
  right: -16px;
  width: 18px;
}

.joyride-tooltip.right {
  margin-left: 18px;
}

.joyride-tooltip.right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 36px;
  left: -16px;
  width: 18px;
}

.joyride-tooltip__close {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: 10px;
  width: 12px;
  z-index: 10;
  display: block;
}

.joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
  outline: none;
}

.joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
  color: rgba(59, 59, 59, 0.5);
  outline: none;
}

.joyride-tooltip__close--header {
  right: 20px;
  top: 20px;
}

.joyride-tooltip__header {
  border-bottom: 1px solid #f04;
  color: #555;
  font-size: 20px;
  padding-bottom: 6px;
  padding-right: 18px;
  position: relative;
}

.joyride-tooltip__header ~ .joyride-tooltip__main {
  padding: 12px 0 18px;
}

.joyride-tooltip__main {
  font-size: 16px;
  padding-bottom: 18px;
  padding-right: 18px;
}

.joyride-tooltip__footer {
  text-align: right;
}

.joyride-tooltip__button {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
}

.joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
  outline: none;
}

.joyride-tooltip__button--primary {
  background-color: #f04;
  border-radius: 4px;
  color: #fff;
  padding: 6px 12px;
  transition: background-color 0.2s ease-in-out;
}

.joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
  background-color: #ff1f5a;
  color: #fff;
}

.joyride-tooltip__button--secondary {
  color: #f04;
  margin-right: 10px;
}

.joyride-tooltip__button--skip {
  color: #ccc;
  float: left;
  margin-right: 10px;
}

.joyride-tooltip--standalone .joyride-tooltip__main {
  padding-bottom: 0;
}

.joyride-tooltip--standalone .joyride-tooltip__footer {
  display: none;
}

@media screen and (min-width: 480px) {
  .joyride-tooltip {
    width: 360px;
  }
}
@media screen and (min-width: 960px) {
  .joyride-tooltip {
    width: 450px;
  }
}
@keyframes joyride-tooltip {
  0% {
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: perspective(1px) scale(1);
  }
}
@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.7;
  }
}
@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1);
  }
  45% {
    opacity: 0.7;
    transform: scale(0.75);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}