@charset "CP852";
html {
    font-size: 16px;
}

a {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #1396e2;
    outline: none !important;
}

a:hover {
    text-decoration: none;
}

a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4 {
    color: #1396e2;
}

a:focus,
a:active {
    outline: none !important;
    text-decoration: none;
}

a h1,
a h2,
a h3,
a h4 {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #073855;
}

blockquote {
    border: none;
    display: table;
    margin-bottom: 0;
    padding: 20px 10%;
    width: 100%;
}

blockquote figure {
    display: table-cell;
    vertical-align: middle;
}

blockquote figure .image {
    background-color: #5a5a5a;
    height: 180px;
    position: relative;
    overflow: hidden;
    width: 160px;
}

blockquote figure .image:after {
    bottom: 0px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    right: 0;
    width: 0px;
}

blockquote figure .image:before {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 20px;
    left: 0;
    width: calc(100% - 20px);
    position: absolute;
    z-index: 1;
}

blockquote .cite {
    display: table-cell;
    vertical-align: middle;
    padding-left: 30px;
}

blockquote .cite p {
    font-family: Georgia, serif;
    font-size: 24px;
    font-style: italic;
}

blockquote .cite footer {
    color: #073855;
}

body {
    background-color: #fff;
    color: #5a5a5a;
    font-family: 'Roboto', sans-serif;
}

dl {
    margin-bottom: 10px;
}

dl dt {
    float: left;
}

dl dd {
    margin-bottom: 8px;
    text-align: right;
}

dl dd .rating {
    float: right;
    position: relative;
    top: -5px;
}

h1 {
    font-size: 28px;
    font-weight: lighter;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 10px;
    padding-bottom: 15px;
}

h2 {
    font-size: 24px;
    font-weight: lighter;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 10px;
    padding-bottom: 15px;
}

h3 {
    font-size: 18px;
    font-weight: lighter;
    margin-bottom: 20px;
}

h4 {
    font-size: 14px;
    font-weight: lighter;
}

hr {
    border-color: rgba(0, 0, 0, 0.1);
}

hr.thick {
    border-width: 2px;
}

hr.divider {
    border-color: #1396e2;
    border-width: 2px;
    width: 40px;
}

p {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=90);
    opacity: 0.9;
    font-family: 'Roboto', sans-serif;
}

section {
    position: relative;
}

ul.list-links {
    margin-top: -5px;
}

ul.list-links li a {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #5a5a5a;
    display: block;
    padding: 5px 0;
    padding-left: 0;
}

ul.list-links li a:hover {
    color: #1396e2;
    padding-left: 5px;
}


/* 2. Styling for specific pages */

.page-sub-page #page-content:after {
    background: #f1f1f1;
    background: -moz-linear-gradient(top, #f1f1f1 0%, white 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f1f1f1), color-stop(80%, white));
    background: -webkit-linear-gradient(top, #f1f1f1 0%, white 80%);
    background: -o-linear-gradient(top, #f1f1f1 0%, white 80%);
    background: -ms-linear-gradient(top, #f1f1f1 0%, white 80%);
    background: linear-gradient(to bottom, #f1f1f1 0%, white 80%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0);
    content: '';
    left: 0;
    height: 110px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.page-sub-page.page-submit h2 {
    border: none;
    margin-bottom: 25px;
    padding-bottom: 0;
}

.page-sub-page.page-submit .submit-pricing {
    margin-bottom: 0;
}

.page-sub-page.page-create-account .radio {
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 30px;
}

.page-sub-page.page-create-account h3 {
    margin-top: 0;
}

.page-sub-page.page-create-agency #page-content form>section {
    margin-bottom: 30px;
}

.page-sub-page.page-contact #page-content section {
    margin-bottom: 30px;
}

.page-sub-page.page-legal #page-content section {
    margin-bottom: 40px;
}

.page-sub-page.page-legal #page-content section h3 {
    font-weight: normal;
}

.page-sub-page.page-agency-detail #page-content address {
    position: relative;
}

.page-sub-page.page-about-us .background-image {
    overflow: visible;
}

.page-sub-page.page-about-us .agent .wrapper aside {
    display: none;
}

.page-sub-page.page-about-us #our-team .agent .wrapper {
    padding-left: 110px;
}

.page-sub-page.page-about-us #our-team .agent .agent-image {
    width: 90px;
}


/* 3. Universal classes */

.background-color-grey-light {
    background-color: #f3f3f3;
}

.background-color-grey-medium {
    background-color: #5a5a5a;
}

.background-color-grey-dark {
    background-color: #2a2a2a;
}

.background-color-default {
    background-color: #1396e2;
}

.background-color-default-darker {
    background-color: #073855;
}

.background-image {
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    overflow: hidden;
}

.blurry-text {
    color: transparent;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
}

.white-blurry-text {
    color: transparent;
    text-shadow: rgba(255, 255, 255, 1) 0px 0px 5px;
}

.button-icon .fas {
    margin: 0 10px;
}

.center {
    text-align: center;
}

#overlay {
    background: rgba(0, 0, 0, 0.3);
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99998;
}

#similar-properties {
    padding-bottom: 20px;
}

#similar-properties h2 {
    margin-bottom: 10px !important;
}

.has-fullscreen-map {
    position: relative;
}

.has-fullscreen-map:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition: 0.8s;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    animation: animate-loading 3s infinite linear;
    -webkit-animation: animate-loading 3s infinite linear;
    content: '\f013';
    color: #2a2a2a;
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 26px;
    height: 30px;
    bottom: 0;
    right: 0;
    margin: auto;
}

.has-fullscreen-map:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition: 0.8s;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background-color: #fff;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.has-fullscreen-map.loaded:before,
.has-fullscreen-map.loaded:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
}

.has-dark-background {
    color: #fff;
}

.has-dark-background a {
    color: #fff;
}

.link-icon {
    color: #2a2a2a;
}

.link-icon .fas {
    color: #1396e2;
    margin: 0 10px;
}

.link-arrow {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #2a2a2a;
    display: inline-block;
}

.link-arrow:after {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    speak: none;
    font-weight: 900;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f105';
    color: #1396e2;
    left: 0;
    font-size: 18px;
    margin-left: 10px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.link-arrow:hover {
    color: #2a2a2a;
    cursor: pointer;
}

.link-arrow:hover:after {
    color: #073855;
    left: 5px;
}

.link-arrow.geo-location:after {
    content: '\f041';
    top: -1px;
}

.link-arrow.geo-location:hover:after {
    left: 0;
    top: -5px;
}

.link-arrow.back:after {
    display: none;
}

.link-arrow.back:before {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    speak: none;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f104';
    color: #1396e2;
    right: 0;
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.link-arrow.back:hover {
    color: #2a2a2a;
    cursor: pointer;
}

.link-arrow.back:hover:before {
    color: #073855;
    right: 5px;
}

.no-border {
    border: none !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.opacity-10 {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=10);
    opacity: 0.1;
}

.opacity-20 {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
}

.text-align-right {
    text-align: right;
}

.text-underline {
    text-decoration: underline;
}


/* 4. Header*/

.navigation {
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    /*margin-bottom: 20px;*/
    position: relative;
    width: 100%;
    z-index: 99;
    top: 0;
    display: flex;
    flex-direction: column;
}

.navigation .u_menu {
    display: none;
}

.navigation .svg-inline--fa {
    display: inline;
}

.navigation:hover .secondary-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.navigation .navbar-collapse {
    padding-right: 0;
}

.navigation .navbar {
    border: none;
    margin-bottom: 0;
    min-height: inherit;
    padding: 30px 0;
    /**/
    display: table;
    width: 100%;
}

.navigation .navbar .collapse {
    display: table-cell !important;
    vertical-align: middle;
    float: none !important;
}

.navigation .navbar .navbar-brand {
    height: inherit;
    line-height: 0;
    padding: 0;
}

.navigation .navbar .navbar-nav {
    float: right;
}

.navigation .navbar .navbar-nav>li:hover>.child-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    visibility: visible;
}

.navigation .navbar .navbar-nav>li:last-child a {
    padding-right: 0;
}

.navigation .navbar .navbar-nav>li.active a {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.navigation .navbar .navbar-nav>li.active a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.navigation .navbar .navbar-nav>li.active .child-navigation a {
    color: #5a5a5a;
}

.navigation .navbar .navbar-nav>li.active .child-navigation a:after {
    display: none;
}

.navigation .navbar .navbar-nav>li.active .child-navigation li:first-child a:after {
    display: block;
}

.navigation .navbar .navbar-nav>li.active .child-navigation.position-bottom li:last-child a:after {
    display: block;
}

.navigation .navbar .navbar-nav>li.has-child {
    position: relative;
}

.navigation .navbar .navbar-nav>li.has-child:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f0d7';
    color: #1396e2;
    font-size: 8px;
    height: 10px;
    position: absolute;
    top: 9px;
    bottom: 0;
    margin: auto;
    right: 2px;
}

.navigation .navbar .navbar-nav>li a {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    color: #2a2a2a;
    line-height: 0;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 9px;
    position: relative;
}

.navigation .navbar .navbar-nav>li a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background-color: #1396e2;
    bottom: -5px;
    content: '';
    left: 16px;
    height: 1px;
    position: absolute;
    width: 10px;
}

.navigation .navbar .navbar-nav>li a:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: transparent;
}

.navigation .navbar .navbar-nav>li a:active,
.navigation .navbar .navbar-nav>li a:focus {
    background-color: transparent;
}

.navigation .navbar .navbar-nav>li>.child-navigation {
    margin-top: 11px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.navigation-to-left {
    right: 15px;
    left: inherit;
}

.navigation .navbar .navbar-nav>li>.child-navigation.navigation-to-left>li:first-child a:after {
    left: inherit;
    right: 10px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.navigation-to-left.position-bottom>li:last-child a:after {
    left: inherit;
    right: 10px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom {
    bottom: 25px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom>li:first-child a:after {
    border-color: transparent !important;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom>li:last-child:hover a:after {
    border-color: #1396e2 transparent transparent transparent;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom>li:last-child>a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: transparent;
    border-color: #f3f3f3 transparent transparent transparent;
    border-style: solid;
    border-width: 7.5px 7.5px 0 7.5px;
    content: '';
    height: 0px;
    position: absolute;
    left: 10px;
    bottom: -7px;
    width: 0px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom>li:last-child>a:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: transparent;
    content: '';
    height: 8px;
    left: 0;
    width: 100%;
    position: absolute;
    bottom: -8px;
}

.navigation .navbar .navbar-nav>li>.child-navigation.position-bottom>li .child-navigation {
    bottom: 0;
    margin-top: inherit;
}

.navigation .navbar .navbar-nav>li>.child-navigation>li {
    position: relative;
    width: 100%;
}

.navigation .navbar .navbar-nav>li>.child-navigation>li:first-child:hover a:hover:after {
    border-color: transparent transparent #1396e2 transparent;
}

.navigation .navbar .navbar-nav>li>.child-navigation>li:first-child a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3 transparent;
    border-style: solid;
    border-width: 0 7.5px 7px 7.5px;
    content: '';
    height: 0px;
    position: absolute;
    left: 10px;
    top: -7px;
    width: 0px;
}

.navigation .navbar .navbar-nav>li>.child-navigation>li:first-child a:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: transparent;
    content: '';
    height: 8px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -8px;
}

.navigation .navbar .navbar-nav>li .child-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    position: absolute;
    visibility: hidden;
    list-style: none;
    padding-left: 0;
    left: 15px;
    min-width: 240px;
    z-index: 100;
}

.navigation .navbar .navbar-nav>li .child-navigation li:hover .child-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    visibility: visible;
}

.navigation .navbar .navbar-nav>li .child-navigation li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    padding: 20px 10px 15px 10px;
    position: relative;
}

.navigation .navbar .navbar-nav>li .child-navigation li a:hover {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #1396e2;
    color: #fff;
}

.navigation .navbar .navbar-nav>li .child-navigation li .child-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    margin-top: -41px;
}

.navigation .navbar .navbar-nav>li.mobile-submit {
    display: none;
}

.navigation .navbar .navbar-nav li .child-navigation li.has-child:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    background-color: transparent;
    color: #1396e2;
    content: '\f105';
    height: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: inherit;
    right: 8px;
    width: 5px;
    z-index: 2;
}

.navigation .navbar .navbar-nav li .child-navigation li.has-child:hover:after {
    color: #fff;
}

.navigation .add-your-property {
    position: absolute;
    right: 0;
    bottom: -50px;
}

.navigation .add-your-property:hover .text {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=90);
    opacity: 0.9;
    right: 50px;
    pointer-events: none;
}

.navigation .add-your-property .btn {
    width: 50px;
    height: 50px;
}

.header-marker {
    font-size: 27px;
}

.navigation .add-your-property .text {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #5a5a5a;
    background-color: #fff;
    padding: 10px;
    position: absolute;
    right: 40px;
    text-align: right;
    top: 0;
    pointer-events: none;
}

.navigation .add-your-property .text:after {
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    margin: auto;
}

.navigation .secondary-navigation {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-bottom: 1px solid #f3f3f3;
    display: table;
    font-size: 12px;
    padding: 5px 0;
    width: 100%;
}

.navigation .secondary-navigation a {
    color: #5a5a5a;
    margin-left: 10px;
}

.navigation .secondary-navigation a:hover {
    color: #073855;
}

.navigation .secondary-navigation a.promoted {
    color: #1396e2;
}

.navigation .secondary-navigation a.promoted:hover {
    color: #073855;
}

.navigation .secondary-navigation .contact {
    float: left;
}

.navigation .secondary-navigation .contact figure {
    margin-right: 10px;
}

.navigation .secondary-navigation .contact figure strong {
    margin-right: 5px;
}

.navigation .secondary-navigation .user-area {
    float: right;
}

.navigation .secondary-navigation .user-area .actions {
    float: left;
}

.navigation .secondary-navigation .user-area .language-bar {
    float: right;
}

.navigation .secondary-navigation figure {
    display: inline-block;
}

.navigation-fixed-bottom .navigation {
    bottom: 0;
    position: absolute;
    top: inherit;
}

.navigation-fixed-top .navigation {
    top: 0;
    position: fixed;
    bottom: inherit;
}

.navigation-fix-to-top {
    position: fixed !important;
    bottom: inherit !important;
    top: 0 !important;
}


/* Specific to IE browser */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    #profile-dropdown-ie {
        width: 152px;
    }
    .nav-bar-max {
        margin-bottom: 8px;
    }
}

#nav-bar-ie {
    display: -ms-grid;
    -ms-grid-columns: max-content;
}


/* browser specific styling end here */

.nav-bar-max {
    width: max-content;
}


/* 5. Footer */

#page-footer .inner {
    display: table;
    width: 100%;
}

#page-footer .inner h3 {
    color: #2a2a2a;
    font-weight: normal;
    margin-bottom: 30px;
    margin-top: 0;
}

#page-footer .inner #footer-main {
    background-color: #f3f3f3;
    padding: 40px 0;
}

#page-footer .inner #footer-copyright {
    background-color: #455560;
    color: #fff;
    display: table;
    padding: 20px 0;
    width: 100%;
}

#page-footer .inner #footer-copyright a {
    color: #fff;
}

#page-footer .inner #footer-copyright a:hover {
    color: #1396e2;
}

#page-footer .inner .property:last-child {
    margin-bottom: 0 !important;
}

#page-footer .inner .property-thumbnail {
    background-color: #1396e2;
    float: left;
    height: 60px;
    overflow: hidden;
}

#page-footer .inner .property-thumbnail:hover img {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

#page-footer .inner .property-thumbnail img {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
    width: 100%;
}


/****
  6. Content
****/

#about-us #ceo-section .cite-title {
    font-size: 36px;
    font-weight: lighter;
}

#about-us #ceo-section h3 {
    color: #073855;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
}

#about-us #ceo-section .cite {
    display: inline-block;
    margin: 20px 0;
    width: 70%;
}

#about-us .image {
    display: inline-block;
    margin-bottom: 10px;
}

#about-us .image img {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

#about-us .divider-image {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=10);
    opacity: 0.1;
    margin: 30px 0;
    max-width: 100%;
}

#about-us .member {
    margin-bottom: 60px;
}


/* Account page */

.account-profile {
    position: relative;
}

.account-profile h3 {
    margin-top: 0;
    margin-bottom: 30px;
}

.account-profile img {
    width: 100%;
    margin-bottom: 30px;
}

.account-profile form {
    position: relative;
}

.account-profile section {
    margin-bottom: 0px;
}

.account-profile section#agency {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.account-profile section#contact label,
.account-profile section#agency label {
    display: block;
    line-height: 38px;
}

.account-profile section#social .input-group {
    width: 100%;
}

.account-profile section#social .input-group .input-group-addon {
    background-color: #2a2a2a;
    width: 40px;
}

.account-profile section#social .input-group .input-group-addon i {
    color: #fff;
}

.account-profile .contact-fields {
    display: table;
    width: 100%;
}

.account-profile .contact-fields dt {
    clear: both;
}

.account-profile .contact-fields dd {
    float: right;
    margin-bottom: 5px;
    width: 65%;
}

.account-profile .switch {
    position: absolute;
    top: 0;
    right: 0;
}

.account-profile .switch label {
    line-height: 0 !important;
}

.account-profile .switch .icheckbox {
    margin-right: 0;
}


/* Geo location */

.geo-location-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
}

.geo-location-wrapper:hover .text {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    right: 50px;
    pointer-events: none;
}

.geo-location-wrapper .btn {
    background-color: #fff;
    color: #073855;
    position: absolute;
    right: 0px;
    top: 60px;
    width: 38px;
}

.geo-location-wrapper .btn:hover {
    background-color: #fff;
    color: #1396e2;
}

.geo-location-wrapper .fas {
    font-size: 20px;
}

.geo-location-wrapper .text {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #fff;
    background-color: #073855;
    padding: 10px;
    position: absolute;
    right: 40px;
    text-align: right;
    top: 0;
    pointer-events: none;
}

.geo-location-wrapper .text:after {
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #073855;
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    margin: auto;
}


/* Agency & Agents */

.agency {
    border-bottom: 1px solid #f3f3f3;
    display: table;
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}

.agency address {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
}

.agency address h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.agency h2 {
    border: none;
    margin-bottom: 20px;
    margin-top: 0;
    padding: 0;
}

.agency dl {
    display: inline-block;
    width: 50%;
}

.agency dl dd {
    margin-bottom: 4px;
}

.agency .agency-image {
    display: table-cell;
    vertical-align: middle;
    width: 30%;
}

.agency .wrapper {
    display: table-cell;
    width: 70%;
}

.agency-image img {
    max-width: 100%;
}

.agent {
    margin-bottom: 60px;
    position: relative;
    padding-left: 180px;
}

.agent .agent-image {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    left: 0;
    position: absolute;
    top: 0;
    width: 160px;
}

.agent .agent-image:hover {
    top: -5px;
}

.agent .agent-image img {
    width: 100%;
}

.agent .wrapper h2 {
    border: none;
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 0;
}

.agent .wrapper aside {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
}

.agent .wrapper dl {
    border-top: 1px solid #f3f3f3;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 12px;
}

.agent .wrapper dl dd {
    margin-bottom: 4px;
}

#agent-detail h3 {
    margin-bottom: 20px;
    margin-top: 0;
}

#agent-detail .property h3 {
    margin: 5px 0 3px 0;
}

#agent-detail .agent-image img {
    width: 100%;
}

#agent-detail .agent-social .btn {
    width: 36px;
}

#agent-detail .agency-logo {
    display: block;
    position: relative;
}

#agent-detail .agency-logo:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f105';
    color: #1396e2;
    font-size: 18px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 20px;
    right: 20px;
}

#agent-detail .agency-logo:hover:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    right: 5px;
}

#agent-detail .agency-logo img {
    max-width: 100%;
}

.bar-stats {
    height: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: #128dd4 2px solid;
    position: relative;
}

.progress-stat-bar {
    padding-left: 10px;
}

.progress-stat-bar li {
    height: 128px;
    width: 20px;
    background: #f1f1f1;
    position: relative;
    margin-right: 5px;
    float: left;
    list-style: none;
}

.progress-stat-percent {
    background: #128dd4;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.bar-legend {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 0;
}

.bar-legend li {
    font-size: 11px;
    margin-bottom: 5px;
    list-style: none;
}

.bar-legend-pointer {
    height: 10px;
    width: 10px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 5px;
}

.bar-legend-pointer.green {
    background: #aec785;
}

.donut-box {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

#agent-detail .rev-pie {
    margin-top: 30px;
}

.rev-pie {
    min-height: 164px;
    height: 90px;
    width: 90%;
    display: block;
}

#agent-detail .md-pie {
    margin-top: 30px;
}

.md-pie {
    min-height: 164px;
    height: 90px;
    width: 90%;
    display: block;
}

#agent-detail .sm-pie {
    margin-top: 30px;
}

.sm-pie {
    min-height: 164px;
    height: 90px;
    width: 90%;
    display: block;
}

.sm-pie .legendColorBox {
    padding-right: 5px;
}

.sm-pie .legendColorBox>div {
    border: #fff 0px solid !important;
}

.sm-pie .legendColorBox div {
    border-radius: 100% !important;
}

.rev-pie .legendColorBox {
    padding-right: 5px;
}

.rev-pie .legendColorBox>div {
    border: #fff 0px solid !important;
}

.rev-pie .legendColorBox div {
    border-radius: 100% !important;
}

.md-pie .legendColorBox {
    padding-right: 5px;
}

.md-pie .legendColorBox>div {
    border: #fff 0px solid !important;
}

.md-pie .legendColorBox div {
    border-radius: 100% !important;
}

.agent-form .agent-info {
    padding-left: 140px;
    position: relative;
}

.agent-form .agent-info h3 {
    margin-top: 0;
}

.agent-form .agent-info hr {
    margin: 10px 0;
}

.agent-form .agent-info figure {
    left: 0;
    position: absolute;
    top: 0;
}

.agent-form .agent-info figure img {
    width: 120px;
}

.animate-loading {
    -webkit-animation-name: animate-loading;
    -moz-animation-name: animate-loading;
    -ms-animation-name: animate-loading;
    animation-name: animate-loading;
    -webkit-animation-duration: infinite;
    -moz-animation-duration: infinite;
    -ms-animation-duration: infinite;
    animation-duration: infinite;
}

@-webkit-keyframes animate-loading {}

@-moz-keyframes animate-loading {}

@-ms-keyframes animate-loading {}

@keyframes animate-loading {}

@-webkit-keyframes animate-loading {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes animate-loading {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes animate-loading {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/****
  B
****/

.banner {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #f3f3f3;
    display: table;
    width: 100%;
    height: 100%;
    padding: 30px;
    margin-bottom: 20px;
    background: #fff;
}

.banner:hover {
    border-color: #d9d9d9;
}

.banner .title,
.banner .submit {
    display: table-cell;
    vertical-align: middle;
}

.banner .continuity-submit {
    display: table-cell;
    vertical-align: middle;
}

.banner .title {
    color: #2a2a2a;
    font-size: 24px;
    font-weight: lighter;
    width: 70%;
}

.banner .sub-title {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: lighter;
    width: 70%;
}

.banner .continuity-sub-title {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: lighter;
    width: 60%;
    cursor: default;
}

.banner .submit {
    color: #1396e2;
    font-size: 18px;
    text-align: right;
    width: 30%;
}

.banner .continuity-submit {
    color: #1396e2;
    font-size: 18px;
    text-align: right;
    width: 40%;
}

.banner .submit i {
    color: #073855;
    margin-left: 10px;
}

.banner .continuity-submit i {
    color: #073855;
    margin-left: 10px;
}

.breadcrumb {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    background-color: transparent;
    padding: 8px 0;
    font-size: 12px;
    margin-bottom: 10px;
}

.breadcrumb a {
    color: #5a5a5a;
}

.block {
    padding: 25px 0;
}


/* Blog */

.blog-post {
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 60px;
    padding-bottom: 30px;
}

.blog-post iframe {
    margin-bottom: 20px;
}

.blog-post img {
    max-width: 100%;
}

.blog-post header h2 {
    border: none;
    font-size: 36px;
    margin: 20px 0;
    padding-bottom: 0;
}

.blog-post p {
    margin-bottom: 20px;
}

.blog-post ul {
    padding-left: 25px;
}

.blog-post .meta {
    display: table;
    width: 100%;
    margin: 20px 0;
}

.blog-post .meta .link-icon:first-child .fas {
    margin-left: 0;
}

.blog-post .meta .tags {
    display: inline-block;
    float: right;
}

.blog-post .meta .tags .tag {
    margin-left: 5px;
}

.bookmark {
    position: relative;
}

.bookmark:before {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f08a';
    color: #1396e2;
    font-size: 18px;
    position: relative;
}

.bookmark:hover:before {
    color: #073855;
}

.bookmark:hover .title-add {
    visibility: visible;
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    right: 30px;
}

.bookmark .title-add,
.bookmark .title-added {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    visibility: hidden;
    color: #5a5a5a;
    font-size: 12px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    right: 25px;
    text-align: right;
    width: 140px;
}

.bookmark-added {
    position: relative;
}

.bookmark-added:before {
    content: '\f004';
}

.bookmark-added:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    animation: animate-bookmark 1s;
    -webkit-animation: animate-bookmark 1s;
    content: '\f055';
    color: #1396e2;
    left: 3px;
    font-size: 14px;
    position: absolute;
    z-index: 2;
}

.bookmark-added .title-add {
    -webkit-opacity: 0 !important;
    opacity: 0 !important;
}

.bookmark-added .title-added {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    visibility: visible;
}

.animate-bookmark {
    -webkit-animation-name: animate-bookmark;
    -moz-animation-name: animate-bookmark;
    -ms-animation-name: animate-bookmark;
    animation-name: animate-bookmark;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
}

@-webkit-keyframes animate-bookmark {}

@-moz-keyframes animate-bookmark {}

@-ms-keyframes animate-bookmark {}

@keyframes animate-bookmark {}

@-webkit-keyframes animate-bookmark {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        top: 0px;
    }
    30% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes animate-bookmark {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        top: 0px;
    }
    30% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes animate-bookmark {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        top: 0px;
    }
    30% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes animate-bookmark {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        top: 0px;
    }
    30% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        top: -20px;
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}


/****
  C
****/

.cite {
    color: rgba(0, 0, 0, 0.5);
    font-family: 'Georgia', serif;
    font-size: 18px;
    font-style: italic;
    margin: 10px 0;
}

.comments {
    list-style: none;
    padding-left: 0;
}

.comments .comment {
    display: table;
    margin-bottom: 20px;
    padding-left: 80px;
    position: relative;
}

.comments .comment figure {
    left: 0;
    position: absolute;
    top: 0;
}

.comments .comment figure .image {
    background-color: #5a5a5a;
    height: 70px;
    position: relative;
    overflow: hidden;
    width: 60px;
}

.comments .comment figure .image:after {
    bottom: 0px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    right: 0;
    width: 0px;
}

.comments .comment figure .image:before {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    width: calc(100% - 10px);
    position: absolute;
}

.comments .comment figure .image img {
    height: 100%;
}

.comments .comment .date {
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 5px;
}

.comments .comment .date .fas {
    color: #b8b8b8;
    font-size: 10px;
    margin-right: 10px;
}

.comments .comment .name {
    display: table;
    font-size: 18px;
    width: 100%;
}

.comments .comment .reply {
    color: #2a2a2a;
}

.comments .comment .reply .fas {
    color: #1396e2;
    margin-right: 10px;
}


/****
  D
****/

.display-lines .property {
    padding-left: 280px;
    position: relative;
    overflow: visible;
}

.display-lines .property.no-border {
    padding-bottom: 0;
}

.display-lines .property:hover img {
    top: inherit;
}

.display-lines .property .property-image {
    height: 195px;
    left: 0;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 260px;
}

.property-image-user-profile {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    width: 100%;
    object-fit: scale-down;
}

.display-lines .property .type {
    top: 0;
    right: 0;
}

.display-lines .property .info {
    position: relative;
    min-height: 224px;
    min-width: 110px;
}

.display-lines .property .info header a {
    display: inline-block;
}

.display-lines .property .info header figure {
    margin-bottom: 10px;
}

.display-lines .property .info header h3 {
    color: #073855;
    font-size: 24px;
    margin: 0;
}

.display-lines .property .info aside {
    margin-top: 10px;
}

.display-lines .property .info aside p {
    float: left;
    max-height: 55px;
    font-size: 12px;
    width: 60%;
    overflow: hidden;
    padding-right: 10px;
    padding-bottom: 20px;
}

.display-lines .property .info aside dl {
    float: right;
    width: 40%;
    font-size: 12px;
}

.display-lines .property .info aside dl dd {
    margin-bottom: 4px;
}

.display-lines .property .info .link-arrow {
    bottom: 0;
    left: 0;
    position: relative;
}

.display-lines .property .ribbon {
    margin: inherit;
    left: initial;
}

.recognition-level {
    color: #5489B6;
}


/****
  E
****/

.enabled {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    pointer-events: inherit;
}

.disabled {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
    pointer-events: none;
}

.error-page {
    display: table;
    text-align: center;
    margin-top: 100px;
    width: 100%;
}

.error-page h2 {
    position: relative;
    z-index: 4;
}

.error-page .title header {
    color: #1396e2;
    font-size: 99px;
    font-weight: lighter;
    position: relative;
    z-index: 1;
}

.error-page .top {
    bottom: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    z-index: 2;
}

.error-page .bottom {
    bottom: 90px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    z-index: 0;
}


/****
  F
****/

.faq {
    position: relative;
    padding-left: 50px;
    margin-bottom: 50px;
}

.faq .icon {
    background-color: #1396e2;
    color: #fff;
    height: 35px;
    left: 0;
    width: 35px;
    position: absolute;
    top: 0;
    text-align: center;
    line-height: 35px;
}

.faq .icon:after {
    bottom: -7px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    border-color: transparent #1396e2 transparent transparent;
    position: absolute;
    right: 0;
    width: 0px;
}

.faq header {
    color: #073855;
    font-size: 18px;
    margin-top: 6px;
    margin-bottom: 15px;
}

.faq p {
    border-bottom: 2px solid #f3f3f3;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.faq aside {
    font-size: 12px;
}

.faq aside a {
    margin-left: 10px;
}

.feature-box {
    border: 2px solid #f3f3f3;
    margin-bottom: 30px;
    padding: 0 20px 15px 80px;
    position: relative;
}

.feature-box .description h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #073855;
    font-weight: normal;
    padding-bottom: 10px;
}

.feature-box .icon {
    background-color: #1396e2;
    height: 60px;
    left: -2px;
    padding: 20px;
    position: absolute;
    top: -2px;
    width: 60px;
}

.feature-box .icon:after {
    bottom: -8px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent #1396e2 transparent transparent;
    position: absolute;
    right: 0;
    width: 0px;
}

.feature-box .icon .fas {
    color: #fff;
    font-size: 20px;
}

.featured-properties {
    padding-bottom: 0;
    padding-top: 40px;
}

#featured-properties-carousel {
    background-color: #fff;
}

.carousel-control .fa-chevron-left {
    position: relative;
    top: 40%;
    margin-left: -256px;
    height: 64px;
    width: 64px;
    color: black;
}

.carousel-control .fa-chevron-right {
    position: relative;
    top: 40%;
    margin-left: 175px;
    height: 64px;
    width: 64px;
    color: black;
}

.carousel-indicators {
    bottom: -46px;
}

.carousel-indicators li {
    border: 1px solid #000;
}

.carousel-indicators .active {
    background-color: #000;
}

.carousel {
    margin-bottom: 60px;
}

.fun-facts {
    display: table;
    margin-bottom: 15px;
    padding: 30px 0;
    position: relative;
    width: 100%;
}

.fun-facts:before {
    background-color: rgba(0, 0, 0, 0.07);
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    top: -15px;
    right: 0;
    width: 100%;
    z-index: -2;
}

.fun-facts .number-wrapper {
    text-align: center;
}

.fun-facts .number-wrapper figure {
    color: #073855;
}

.fun-facts .number-wrapper .number {
    color: #1396e2;
    font-size: 48px;
    font-weight: lighter;
    position: relative;
}

.fun-facts .number-wrapper .number:before {
    background-color: #fff;
    bottom: 0;
    content: '';
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60%;
    z-index: -1;
}

.flip-enter {
    opacity: 0;
    transform: rotateY(90deg);
}
.flip-enter-active {
    opacity: 1;
    transform: rotateY(0deg);
    transition: opacity 500ms, transform 500ms;
}
.flip-exit {
    opacity: 1;
    transform: rotateY(0deg);
}
.flip-exit-active {
    opacity: 0;
    transform: rotateY(90deg);
    transition: opacity 500ms, transform 500ms;
}


/****
  G
****/

.goals {
    margin-top: 20px;
    margin-bottom: 40px;
}

.goals header h1,
.goals header h2,
.goals header h3 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-top: 21px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.goals header h1 {
    margin-top: 10px;
}

.goals .goals-side {
    margin-top: 21px;
    text-align: right;
}

.goals .goals-side .btn {
    margin-bottom: 15px;
}

.goals .goals-side .btn i {
    margin-right: 5px;
}

.goals .goals-details-info h3 {
    margin-bottom: 0;
}

.goals .goals-details-info p {
    color: #d9534f;
}

.goals .goal-details-list {
    display: flex;
    padding: 20px;
    border: 2px solid #f3f3f3;
    transition: background 0.4s;
}

.goals .goal-details-list:hover {
    background: #f3f3f3;
}

.goals .goal-details-list:hover .progress-bar-vertical {
    background: #fff;
}

.goals .goal-details-list .list-group {
    margin: 0 0 0 10px;
}

.goals .progress {
    border-radius: 0;
    box-shadow: none;
}

.goals .progress-bar-vertical {
    width: 20px;
    min-height: 120px;
    display: flex;
    align-items: flex-end;
    margin: 0;
    float: left;
}

.goals .progress-bar-vertical .progress-bar {
    width: 100%;
}

.goals .list-group-item {
    padding: 10px 0;
    margin-bottom: 0;
    border: none;
    border-bottom: solid 1px #ddd;
    background-color: transparent;
    border-radius: 0;
}

.goals .list-group-item:last-child {
    border: none;
}

.goals .list-group-item .checkbox {
    margin: 0;
}

.goals .list-group-item .checkbox label {
    padding-left: 0;
}

.goals .recommend {
    margin-top: 40px;
}

.goals .recommend-box {
    padding: 20px;
    background-color: #f3f3f3;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.goals .recommend-box a h4 {
    color: #1396e2;
}

.goals .recommend-box a:hover h4,
.goals .recommend-box a:focus h4 {
    color: #23527c;
}

.goals .current-goals {
    margin-bottom: 20px;
}

.goals .current-goals-side {
    height: 120px;
}

.goals .current-goal-side-inner {
    position: relative;
    top: 50%;
    text-align: right;
    transform: perspective(1px) translateY(-50%);
}

.goals .current-goals-side h4 {
    margin: 0 0 5px;
}

.goals .current-goals-box {
    padding: 15px;
    border: 2px solid #f3f3f3;
    transition: background 0.4s;
}

.goals .current-goals-box:hover {
    background: #f3f3f3;
}

.goals .current-goals-box:hover .progress {
    background: #fff;
}

.goals .current-goals-box .progress {
    margin-bottom: 10px;
}

.goals .next-steps .list-group-item {
    transition: padding 0.2s;
}

.goals .next-steps .list-group-item:hover {
    padding-left: 5px;
}

.goals .next-steps .list-group-item i {
    margin-left: 5px;
}

.grid-item {
    float: left;
    width: 270px;
    border-radius: 4px;
    margin: 6px;
    background: white;
    height: 550px;
    overflow: hidden;
    display: block;
}

.grid-image {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    width: 100%;
}

.grid-image .aside {
    padding: 8px;
}

.grid-content {
    padding: 8px;
}


/****
  I
****/

.invoice {
    width: 100%;
}

.invoice aside {
    display: inline-block;
    width: 49%;
}

.invoice address {
    line-height: 24px;
    margin: 20px 0;
}

.invoice table {
    width: 100%;
}

.invoice section {
    border-bottom: 2px solid #ccc;
    padding: 20px 0;
}

.invoice dl dd {
    text-align: left;
}

.invoice dl dt {
    width: 150px;
}

.invoice h1 {
    border: none;
    font-size: 60px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
}

.invoice h2 {
    border: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.invoice h3 {
    border: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 24px;
}

.invoice h4 {
    font-weight: bold;
}

.invoice .title {
    font-weight: bold;
}

.invoice #description-table th {
    background-color: #e8e8e8;
    padding: 8px 0;
}

.invoice #description-table th:first-child {
    padding-left: 10px;
    width: 50%;
}

.invoice #description-table th:last-child {
    text-align: right;
    padding-right: 10px;
    width: 18%;
}

.invoice #description-table td {
    padding: 10px 0;
}

.invoice #description-table td:first-child {
    padding-left: 10px;
}

.invoice #description-table td:last-child {
    text-align: right;
    padding-right: 10px;
}

.invoice #description {
    padding-bottom: 0;
}

.invoice #subtotal aside:last-child {
    width: 18%;
}

.invoice #summary {
    text-align: right;
}

.invoice #summary h2 {
    display: inline-block;
}

.invoice #summary figure {
    display: inline-block;
    font-size: 24px;
    width: 18%;
}

.invoice #from-to>table>tbody>tr>td {
    width: 50%;
    vertical-align: top;
}

.invoice #underline {
    font-size: 12px;
}


/****
  L
****/

.language-bar {
    float: right;
    margin-left: 15px;
}

.language-bar a {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-left: 5px !important;
}

.language-bar a.active {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.language-bar a:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.layout-expandable {
    -moz-transition: 0.8s;
    -webkit-transition: 0.8s;
    transition: 0.8s;
}

#loading-icon {
    bottom: 0;
    font-size: 28px;
    height: 25px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    z-index: 3;
}

.loading:before {
    background-color: #fff;
    content: '';
    height: 100%;
    left: 0;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
}

.logos {
    display: block;
    line-height: 0;
    margin-top: 10px;
    padding: 30px 10px;
    text-align: justify;
}

.logos:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.logos .logo {
    display: inline-block;
    margin-bottom: 7px;
    position: relative;
}

.logos .logo a {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    padding: 10px;
}

.logos .logo a:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}


/****
  N
****/

.note {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    font-size: 12px;
    margin: 10px 0;
}


/****
  M
****/

.masonry-hide-other {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
}

.masonry-show {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.member {
    position: relative;
    margin-bottom: 30px;
    padding-left: 130px;
}

.member h3 {
    color: #073855;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
}

.member .image {
    left: 0;
    position: absolute;
    top: 0;
}

.member .image img {
    width: 110px;
}

.member dl {
    border-top: 2px solid #f3f3f3;
    font-size: 12px;
    margin-top: 10px;
    padding-top: 10px;
}

.member dl dd {
    margin-bottom: 4px;
}

.member .tag {
    background-color: #1396e2;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
}

.my-properties table {
    width: 100%;
}

.my-properties table h2 {
    border: none;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px 0;
    padding: 0;
}

.my-properties table thead {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.my-properties table thead tr th {
    background-color: #073855;
    border: none;
    color: #fff;
    padding: 10px 10px 10px 0px;
}

.my-properties table thead tr th:first-child {
    padding-left: 10px;
}

.my-properties table tbody tr td {
    border-top: none;
    border-bottom: 2px solid #f3f3f3;
    padding: 20px 20px 20px 0px;
    vertical-align: middle;
}

.my-properties table tbody tr td:last-child {
    padding-right: 5px;
}

.my-properties table tbody tr td.actions {
    text-align: right;
}

.my-properties table tbody tr td.actions a {
    padding: 5px;
}

.my-properties table tbody tr td.actions .edit {
    float: left;
    color: #2a2a2a;
}

.my-properties table tbody tr td.actions .edit:hover i {
    color: #073855;
}

.my-properties table tbody tr td.actions .edit i {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #1396e2;
    margin-right: 5px;
}

.my-properties table tbody tr td.actions .delete {
    color: red;
    position: relative;
    top: 5px;
}

.my-properties table tbody tr td.image {
    width: 125px;
}

.my-properties table tbody tr td.image img {
    width: 100%;
}

.my-properties table tbody tr td img,
.my-properties table tbody tr td .inner {
    display: inline-block;
}

.my-properties table tbody tr td .inner figure {
    margin-bottom: 5px;
}

.nav li {
    padding-top: 4px;
}

.nav li.icon {
    padding: 0;
}

.nav li.icon a {
    opacity: 1.0;
    padding: 0;
}


/****
  O
****/

.owl-carousel .property {
    margin-bottom: 0;
}

.owl-carousel .owl-pagination {
    text-align: center;
}

.owl-carousel .owl-pagination .owl-page {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.owl-carousel .owl-pagination .owl-page:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.owl-carousel .owl-pagination .owl-page.active {
    background-color: rgba(0, 0, 0, 0.6);
}

.owl-carousel .owl-dots {
    text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.owl-carousel .owl-dots .owl-dot:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.owl-carousel .owl-dots .owl-dot.active {
    background-color: rgba(0, 0, 0, 0.6);
}


/****
  P
****/

#page-content {
    padding-bottom: 10px;
    position: relative;
    min-height: calc(100vh - 170px);
}

.pagination li:first-child a,
.pagination li:last-child a {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.pagination li.active a {
    background-color: transparent;
    border-color: #073855;
    color: #073855;
}

.pagination li.active a:hover,
.pagination li.active a:active,
.pagination li.active a:focus {
    background-color: transparent;
    border-color: #073855;
    color: #073855;
}

.pagination li a {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent;
    border: none;
    border-top: 5px solid #f3f3f3;
    color: #5a5a5a;
    padding: 10px 20px;
}

.pagination li a:hover,
.pagination li a:active,
.pagination li a:focus {
    background-color: transparent;
    border-color: #1396e2;
    color: #1396e2;
}

.post-author {
    border: 2px solid #f3f3f3;
    display: table;
    margin-bottom: 60px;
    padding: 20px;
    position: relative;
    width: 100%;
}

.post-author img {
    display: table-cell;
    vertical-align: middle;
    width: 100px;
    /*position: absolute; height: 100px; top: 0; bottom: 0; margin: auto;*/
}

.post-author header {
    color: #073855;
    margin-bottom: 10px;
}

.post-author .wrapper {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
}

.price-box {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #f3f3f3;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 30px;
    position: relative;
    bottom: 0;
}

.price-box:hover {
    bottom: 10px;
}

.price-box.promoted {
    background-color: #062f47;
    border: none;
}

.price-box.promoted header,
.price-box.promoted .price {
    margin: 0;
}

.price-box.promoted ul {
    color: #fff;
}

.price-box.promoted ul li {
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.price-box header {
    background-color: #1396e2;
    margin: -2px;
    padding: 20px 0;
}

.price-box header h2 {
    border: none;
    color: #fff;
    font-size: 36px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
}

.price-box .price {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #90979c;
    color: #fff;
    margin: 0 -2px;
    padding: 10px 0;
}

.price-box .price figure {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: -5px;
}

.price-box .price small {
    font-size: 12px;
}

.price-box ul {
    list-style: none;
    padding-left: 0;
    padding-bottom: 10px;
}

.price-box ul li {
    border-bottom: 2px solid #f3f3f3;
    padding: 10px 0;
}

.price-box ul li.not-available {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    text-decoration: line-through;
}

.price-box ul li span {
    font-weight: bold;
}

.green-background {
    background-color: #4cae4c !important;
}

.green-foreground {
    color: #4cae4c !important;
}

.red-foreground {
    color: #ae1008 !important;
}

.property {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.property:hover img,
.property:active img,
.property:focus img {
    -moz-backface-visibility: hidden;
    top: -10px;
}

.property:hover.big .overlay,
.property:active.big .overlay,
.property:focus.big .overlay {
    bottom: 0px;
}

.property:hover .overlay,
.property:active .overlay,
.property:focus .overlay {
    bottom: 0px;
}

.property:hover .overlay .additional-info li,
.property:active .overlay .additional-info li,
.property:focus .overlay .additional-info li {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.property:hover .tag.status,
.property:active .tag.status,
.property:focus .tag.status {
    background-color: rgba(0, 0, 0, 0.8);
}

.property:hover .type,
.property:active .type,
.property:focus .type {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.property img {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -moz-backface-visibility: hidden;
    width: 100%;
    position: relative;
    top: 0;
}

.property .overlay {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    bottom: -50px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.property .overlay h3 {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0 3px 0;
}

.property .overlay figure {
    color: #fff;
    font-weight: lighter;
}

.property .overlay .additional-info {
    background-color: #073855;
    display: table;
    height: 30px;
    list-style: none;
    margin-bottom: 0;
    width: 100%;
    padding: 8px 12px;
}

.property .overlay .additional-info li {
    -moz-transition: 0.8s;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    color: #fff;
    display: table-cell;
    width: 25%;
}

.property .overlay .additional-info li header {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: 11px;
}

.property .overlay .additional-info li figure {
    font-size: 11px;
    font-weight: bold;
}

.property .overlay .info {
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.75)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    padding: 12px;
}

.property .property-image {
    overflow: hidden;
    width: 100%;
}

.property .tag.status {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    left: 13px;
    font-size: 12px;
    padding: 6px 9px;
    position: absolute;
    top: 15px;
    z-index: 1;
}

.property .tag.status:after {
    bottom: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 0;
    border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
    content: '';
    left: 0;
    position: absolute;
}

.property .type {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=90);
    opacity: 0.9;
    background-color: #fff;
    position: absolute;
    right: 13px;
    z-index: 2;
    top: 15px;
    height: 29px;
    padding: 0 2px;
    text-align: center;
    line-height: 29px;
}

.property .type img {
    width: inherit;
    top: 0 !important;
}

.property.big {
    max-width: 440px;
}

.property.big .overlay {
    bottom: -70px;
}

.property.big .overlay h3 {
    font-size: 24px;
    font-weight: normal;
    margin: 8px 0 3px 0;
}

.property.big .overlay li header,
.property.big .overlay li figure {
    font-size: 14px;
}

.property.big .overlay .additional-info {
    height: 70px;
    padding: 15px 20px;
}

.property.big .overlay .price {
    font-size: 18px;
}

.property.big .overlay .info {
    padding: 20px;
}

.property.big .property-image {
    /*height: 285px;*/
}

.property.big .tag {
    left: 20px;
    top: 20px;
}

.property.small {
    margin-bottom: 30px !important;
}

.property.small:hover img {
    top: inherit;
}

.property.small .property-image {
    float: left;
    width: 100px;
    height: 75px;
}

.property.small .info {
    padding-left: 110px;
}

.property.small .info a {
    color: #fff;
}

.property.small .info a:hover {
    color: #e1e4f3;
}

.property.small .info a h4 {
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
}

.property.small .info figure {
    margin-bottom: 10px;
}

.property.small .info .tag {
    font-size: 12px;
}

.property.masonry {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
    font-size: 12px;
    width: 32.1%;
    float: left;
    margin-bottom: 15px;
    overflow: visible;
}

.property.masonry .inner {
    /*-moz-backface-visibility: hidden;*/
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.property.masonry:hover img {
    top: 0;
}

.property.masonry aside {
    border: 2px solid #f3f3f3;
    background-color: #fff;
    padding: 15px 15px 10px 15px;
    position: relative;
    top: -2px;
}

.property.masonry aside h3 {
    -moz-backface-visibility: hidden;
    color: #073855;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
}

.property.masonry aside figure {
    -moz-backface-visibility: hidden;
    margin-bottom: 10px;
}

.property.masonry aside p {
    -moz-backface-visibility: hidden;
    margin-bottom: 10px;
}

.property.masonry aside .link-arrow {
    border-top: 2px solid #f3f3f3;
    display: block;
    font-size: 14px;
    padding-top: 10px;
}

.property.masonry .property-image {
    position: relative;
    margin-bottom: 0;
}

.property.masonry .property-image img {
    -moz-transition: 0.6s;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.property.masonry .property-image:hover img {
    -moz-transform: scale(1.1) rotate(0.01deg);
    -webkit-transform: scale(1.1) rotate(0.01deg);
    transform: scale(1.1) rotate(0.01deg);
}

.property.masonry .property-image:hover .type img {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
}

.property.masonry .property-image .overlay {
    bottom: 0;
    position: absolute;
}

.property.masonry .property-image .overlay .tag {
    -moz-backface-visibility: hidden;
}

.property.masonry .property-image .ribbon {
    top: 15px;
    bottom: inherit;
}

.carousel-caption p {
    font-size: 18px;
}

.property-carousel .property-slide {
    position: relative;
}

.property-carousel .property-slide .overlay {
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.75)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    bottom: 0;
    left: 0;
    height: 40%;
    position: absolute;
    width: 100%;
}

.property-carousel .property-slide .overlay h3 {
    bottom: 20px;
    color: #fff;
    left: 30px;
    font-size: 24px;
    position: absolute;
}

.property-carousel .property-slide img {
    width: 100%;
}

.property-carousel .owl-controls {
    position: absolute;
    right: 0;
    top: 0;
}

.property-carousel .owl-controls .owl-prev,
.property-carousel .owl-controls .owl-next {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #1396e2;
    display: inline-block !important;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 3 !important;
}

.property-carousel .owl-controls .owl-prev:after,
.property-carousel .owl-controls .owl-next:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #fff;
    width: 5px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.property-carousel .owl-controls .owl-next:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.property-carousel .owl-controls .owl-next:after {
    content: '\f105';
}

.property-carousel .owl-controls .owl-prev:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.property-carousel .owl-controls .owl-prev:after {
    content: '\f104';
}

#property-detail section {
    margin-bottom: 25px;
}

#property-detail section h2 {
    margin-bottom: 25px;
}

.property-detail-map-wrapper {
    position: relative;
    height: 340px;
}

.property-detail-map-wrapper .property-detail-map {
    height: 100%;
    position: absolute;
    right: 0;
    width: 100%;
}

.property-features-list li {
    display: inline-block;
    padding: 5px 0;
    width: 49%;
}

.property-features-list li:before {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    content: '\f111';
    color: #fff;
    font-size: 8px;
    position: relative;
    bottom: 2px;
    margin-right: 10px;
}

.property-title {
    margin-bottom: 30px;
    position: relative;
}

.property-title h1 {
    border: none;
    margin-bottom: 5px;
    margin-top: 0;
    padding-bottom: 0;
}

.property-title .actions {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    right: 0;
}

.property-title .actions a {
    margin-left: 5px;
}

#property-rating aside,
#property-rating figure {
    display: inline-block;
}

#property-rating aside header,
#property-rating figure header {
    font-weight: bold;
    margin-bottom: 10px;
}

#property-rating aside {
    float: left;
}

#property-rating figure {
    float: right;
}

#property-rating figure .rating {
    width: inherit !important;
    float: right;
}

#property-rating .rating-form {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    height: 0px;
    overflow: hidden;
}

#property-rating .rating-form header {
    font-size: 18px;
    font-weight: lighter;
    margin: 20px 0;
}

.show-rating-form {
    height: auto;
}


/****
  R
****/

.rating img {
    background-color: #1396e2;
}

.rating .inner img {
    background-color: #1396e2;
}

.rating.rating-individual {
    margin-bottom: 10px;
}

.rating.rating-user {
    display: inline-block;
}

.rating.rating-user #hint {
    margin-top: 20px;
    height: 20px;
}

.ribbon {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transform: translate3d(0, 0, 1px) rotate(45deg);
    -ms-transform: translate3d(0, 0, 1px) rotate(45deg);
    -webkit-transform: translate3d(0, 0, 1px) rotate(45deg);
    transform: translate3d(0, 0, 1px) rotate(45deg);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #1396e2;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    padding: 6px;
    position: absolute;
    z-index: 3;
    right: -30px;
    top: 15px;
    width: 120px;
    text-align: center;
    margin: auto;
    height: 30px;
    bottom: inherit;
    left: inherit;
}


/****
  S
****/

#search-filter {
    margin-bottom: 40px;
    padding-top: 10px;
    position: relative;
}

#search-filter h3 {
    display: inline-block;
    margin: 0 10px 0 0;
}

#search-filter h3 i {
    color: #1396e2;
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: -2px;
}

#search-filter .search-count {
    font-weight: bold;
}

#search-filter .sorting {
    position: absolute;
    right: 0;
    top: 0px;
}

#search-filter .sorting span,
#search-filter .sorting .form-group {
    display: inline-block;
}

#search-filter .sorting span {
    margin-right: 10px;
}

#search-filter .sorting .form-group {
    min-width: 200px;
}

.section-title {
    position: relative;
}

.section-title .link-arrow {
    margin-top: 5px;
    right: 0;
    position: absolute;
    top: 0;
}

.show-on-map {
    color: #2a2a2a;
    position: absolute;
    right: 0px;
    text-align: center;
}

.show-on-map .fas {
    color: #1396e2;
    font-size: 24px;
}

#sidebar aside {
    margin-bottom: 50px;
}

#sidebar h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 21px;
    padding-bottom: 15px;
}

#sidebar form .btn {
    width: 100%;
}

#sidebar ul {
    padding-left: 15px;
}

#sidebar ul.list-links li {
    color: #1396e2;
}

#sidebar .sidebar-navigation {
    list-style: none;
    padding-left: 0;
}

#sidebar .sidebar-navigation li.active a {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    border-color: #073855;
    padding-left: 10px;
}

#sidebar .sidebar-navigation li.active a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    border-color: transparent transparent transparent #073855;
}

#sidebar .sidebar-navigation li.active a:hover {
    padding-left: 10px;
}

#sidebar .sidebar-navigation li.active i {
    display: inline-block;
}

#sidebar .sidebar-navigation>li {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 5px;
    position: relative;
}

#sidebar .sidebar-navigation>li>a>span.label.label-danger.pull-right {
    margin-right: 10px;
}

#sidebar .sidebar-navigation>li>a>span.label.label-success.pull-right {
    margin-right: 10px;
}

#sidebar .sidebar-navigation>li>a>span.label.label-info.pull-right {
    margin-right: 10px;
}

#sidebar .sidebar-navigation>li a {
    border-right: 3px solid transparent;
    color: #2a2a2a;
    display: block;
    padding: 10px 0 10px 10px;
    position: relative;
}

#sidebar .sidebar-navigation>li a:after {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent transparent;
    content: '';
    position: absolute;
    right: -8px;
    top: 1px;
    bottom: 0;
    margin: auto;
}

#sidebar .sidebar-navigation>li a:hover {
    border-color: #1396e2;
    padding-left: 15px;
}

#sidebar .sidebar-navigation>li a:hover:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    border-color: transparent transparent transparent #1396e2;
}

#sidebar .sidebar-navigation>li i {
    color: #1396e2;
    margin-right: 8px;
}

#sidebar .sidebar-navigation>li ul {
    padding-left: 30px;
    list-style: none;
}

#sidebar .sidebar-navigation>li ul>li {
    border-bottom: 1px solid #f3f3f3;
    position: relative;
}

#sidebar .sidebar-navigation>li ul>li:before {
    content: '•';
    color: #1396e2;
    font-size: 24px;
    position: absolute;
    left: -5px;
    top: 2px;
}

#sidebar .sidebar-navigation>li ul>li ul {
    padding-left: 10px;
}

#sidebar .sidebar-navigation>li ul>li ul li {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    border-bottom: none;
}

#sidebar .sidebar-navigation>li ul>li ul li:before {
    display: none;
}

#slider {
    height: 650px;
    position: relative;
    overflow: hidden;
}

#slider .homepage-slider .slide {
    height: 750px;
    position: relative;
    overflow: hidden;
}

#slider .homepage-slider .slide:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.75)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.75) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    bottom: 0;
    content: '';
    height: 30%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

#slider .homepage-slider .slide .overlay {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #fff;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    width: 100%;
    z-index: 2;
}

#slider .homepage-slider .slide .overlay .info {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

#slider .homepage-slider .slide .overlay .info .tag {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 24px;
    position: relative;
    top: 20px;
}

#slider .homepage-slider .slide .overlay .info h3 {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 30px;
    font-weight: normal;
    margin: 5px 0;
    position: relative;
    left: 20px;
}

#slider .homepage-slider .slide .overlay .info figure {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 18px;
    position: relative;
    left: 20px;
}

#slider .homepage-slider .slide .overlay .info.animate-description-out {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
}

#slider .homepage-slider .slide .overlay .info.animate-description-out .tag,
#slider .homepage-slider .slide .overlay .info.animate-description-out h3,
#slider .homepage-slider .slide .overlay .info.animate-description-out figure {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
}

#slider .homepage-slider .slide .overlay .info.animate-description-in {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

#slider .homepage-slider .slide .overlay .info.animate-description-in figure {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    left: 0px;
}

#slider .homepage-slider .slide .overlay .info.animate-description-in h3 {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    left: 0px;
}

#slider .homepage-slider .slide .overlay .info.animate-description-in .tag {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    top: 0px;
}

#slider .homepage-slider .slide .overlay hr {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
    border-color: #fff;
}

#slider .homepage-slider .slide .overlay .link-arrow {
    color: #fff;
}

#slider .homepage-slider .slide img {
    position: relative;
}

#slider .homepage-slider .owl-controls .owl-prev,
#slider .homepage-slider .owl-controls .owl-next {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: transparent;
    bottom: 0;
    display: block;
    height: 100%;
    margin: auto;
    padding: 0 30px;
    position: absolute;
    top: 0;
}

#slider .homepage-slider .owl-controls .owl-prev:hover,
#slider .homepage-slider .owl-controls .owl-next:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

#slider .homepage-slider .owl-controls .owl-prev:after,
#slider .homepage-slider .owl-controls .owl-next:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    bottom: 0;
    color: #fff;
    font-size: 40px;
    height: 40px;
    margin: auto;
    top: 0px;
    position: absolute;
}

#slider .homepage-slider .owl-controls .owl-next {
    right: 0;
}

#slider .homepage-slider .owl-controls .owl-next:after {
    content: '\f105';
    right: 20px;
}

#slider .homepage-slider .owl-controls .owl-prev:after {
    content: '\f104';
    left: 20px;
}

.show-all {
    color: #2a2a2a;
    padding: 10px;
    display: inline-block;
}

.show-all:hover {
    cursor: pointer;
}

.show-all:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #1396e2;
    content: '\f067';
    margin-left: 10px;
    font-size: 11px;
    position: relative;
    top: -1px;
}

.show-all.layout-expanded:after {
    content: '\f068';
}

.submit-pricing {
    border: 2px solid #f3f3f3;
    padding: 15px 20px;
    margin-bottom: 30px;
}

.submit-pricing table {
    margin-bottom: 0;
}

.submit-pricing table thead tr th {
    border: none;
    font-weight: lighter;
    font-size: 18px;
    padding: 0;
    width: 25%;
    vertical-align: middle;
}

.submit-pricing table thead tr th.title {
    color: #1396e2;
    font-size: 24px;
    text-align: center;
}

.submit-pricing table tbody tr td {
    border: none;
    border-top: 2px solid #f3f3f3;
    font-size: 12px;
    width: 25%;
    vertical-align: middle;
    padding: 10px 0;
    text-align: center;
}

.submit-pricing table tbody tr td:first-child {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    text-align: left;
}

.submit-pricing table tbody tr td.not-available {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
}

.submit-pricing table tbody tr td.available {
    color: #1396e2;
}

.submit-pricing table tbody tr.buttons {
    padding-bottom: 0;
}

.submit-pricing table tbody tr.buttons td {
    border: none;
}

.submit-pricing table tbody tr.buttons td:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #5a5a5a;
    content: '\f05d';
    display: none;
    font-size: 20px;
}

.submit-pricing table tbody tr.buttons td.package-selected:after {
    display: inline-block;
}

.submit-pricing table tbody tr.buttons td.package-selected .btn {
    display: none;
}

.submit-pricing table tbody tr.prices td {
    border: none;
    color: #073855;
    font-size: 14px;
    padding-bottom: 15px;
}

.submit-step {
    padding-left: 50px;
    padding-top: 10px;
    position: relative;
}

.submit-step .step-number {
    background-color: #1396e2;
    color: #fff;
    height: 35px;
    left: 0;
    width: 35px;
    position: absolute;
    top: 0;
    text-align: center;
    line-height: 35px;
}

.submit-step .step-number:after {
    bottom: -7px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    border-color: transparent transparent transparent #1396e2;
    position: absolute;
    left: 0;
    width: 0px;
}

.submit-step .description h4 {
    color: #2a2a2a;
    margin-top: 0;
    font-weight: bold;
}

.submit-features {
    list-style: none;
    padding-left: 0;
}

.submit-features li {
    display: inline-block;
    width: 33%;
}

.submit-features li label {
    font-weight: normal;
}

#submit-map {
    height: 320px;
    width: 100%;
}

.submission-message {
    margin-top: 100px;
}

.submission-message header {
    color: #073855;
    font-size: 48px;
    font-weight: lighter;
    margin: 10px 0;
}

.submission-message p,
.submission-message a {
    margin-bottom: 60px;
}

.step-enter {
    opacity: 0;
    transform: scale(0.9);
}
.step-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms, transform 500ms;
}
.step-exit {
    opacity: 1;
    transform: scale(1);
}
.step-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
}

/****
  T
****/

.tag {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    display: inline-block;
    padding: 3px 8px;
}

.tag.price {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #1396e2;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.tag.error {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: red;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.tag.price a {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #1396e2;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.tag.article {
    background-color: #f3f3f3;
    border-left: 3px solid #c7c7c7;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
}

.testimonials-carousel.small .testimonial {
    padding: 20px 0;
}

.testimonials-carousel.small .testimonial figure .image {
    background-color: #5a5a5a;
    height: 70px;
    position: relative;
    overflow: hidden;
    width: 60px;
}

.testimonials-carousel.small .testimonial figure .image:after {
    bottom: 0px;
    content: '';
    height: 0px;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    right: 0;
    width: 0px;
}

.testimonials-carousel.small .testimonial figure .image:before {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    width: calc(100% - 10px);
    position: absolute;
}

.testimonials-carousel.small .testimonial figure .image img {
    height: 100%;
}

.testimonials-carousel.small .testimonial .cite p {
    font-size: 14px;
}

.testimonials-carousel .testimonial img {
    height: 100%;
    width: initial !important;
}

.text-banner {
    padding: 40px 0;
}

.timeline-item {
    display: block;
    margin-bottom: 60px;
}

.timeline-item:last-child:after {
    display: none !important;
}

.timeline-item:hover .circle .date {
    left: 30px;
}

.timeline-item:hover .wrapper {
    background-color: #f3f3f3;
}

.timeline-item:hover .wrapper .social .btn {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.timeline-item:after {
    background-color: #f3f3f3;
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 21px;
    z-index: -1;
}

.timeline-item .circle {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #f3f3f3;
    height: 43px;
    position: relative;
    width: 100%;
}

.timeline-item .circle .dot {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #1396e2;
    height: 7px;
    width: 7px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 1px;
    margin: auto;
}

.timeline-item .circle .date {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #1396e2;
    color: #fff;
    left: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 29px;
    line-height: 29px;
    padding-left: 7px;
    padding-right: 7px;
    z-index: 2;
}

.timeline-item .circle .date:after {
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border-width: 4px 4px 4px 4px;
    border-color: transparent #1396e2 transparent transparent;
    position: absolute;
    top: 0;
    left: -8px;
    bottom: 0;
    margin: auto;
}

.timeline-item .wrapper {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: transparent;
    border: 2px solid #f3f3f3;
    padding: 20px 20px 20px 80px;
    margin-top: -8px;
}

.timeline-item .wrapper img {
    margin-bottom: 30px;
    width: 100%;
}

.timeline-item .wrapper h3 {
    margin-top: 0;
}

.timeline-item .wrapper .social {
    margin-top: 20px;
}

.timeline-item .wrapper .social .btn {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
    padding: 3px 0;
    width: 25px;
}

.thumbnail {
    height: 100px;
}

.tool-tip {
    padding: 5px;
    cursor: pointer;
    color: #073855;
}


/****
  U
****/

.universal-button {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    color: #2a2a2a;
    display: block;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
    top: 0;
}

.universal-button:hover {
    background-color: #eeeeee;
    color: #2a2a2a;
}

.universal-button:active {
    -moz-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    top: 1px;
}

.universal-button figure,
.universal-button span {
    vertical-align: middle;
    display: table-cell;
}

.universal-button figure {
    color: #1396e2;
    font-size: 28px;
    width: 40px;
}

.universal-button .arrow {
    bottom: 0;
    color: #1396e2;
    height: 15px;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 0;
    width: 10px;
}

#map {
    width: 100%;
    height: 700px;
}

#contact-map {
    height: 300px;
    width: 100%;
}

.marker-style {
    border: 3px solid #1396e2;
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-left: -17px !important;
    margin-top: -46px !important;
}

.marker-style img {
    position: absolute !important;
    top: -1px !important;
    bottom: 0px !important;
    right: 0px;
    left: 0px;
    margin: auto !important;
}

.cluster>div {
    color: #fff !important;
    text-align: center !important;
    z-index: 3;
}

.cluster>div:before {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #1396e2;
    content: '';
    height: 31px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 31px;
    z-index: -1;
}

.leaflet-div-icon {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: transparent;
    border: none;
    background-image: url('../images/marker.png');
}

.leaflet-div-icon:after {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #1396e2;
    content: '';
    display: block;
    height: 34px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 34px;
    z-index: 2;
}

.leaflet-div-icon:hover {
    top: -5px;
}

.leaflet-div-icon img {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -10px;
    bottom: 0px;
}

.leaflet-container {
    font: inherit;
}

.leaflet-popup .leaflet-popup-close-button {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    background: #fff !important;
    color: #2a2a2a !important;
    height: 25px !important;
    top: 10px !important;
    padding: 4px !important;
    right: 10px !important;
    width: 25px !important;
    z-index: 2;
}

.leaflet-popup .leaflet-popup-content-wrapper {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    padding: 0;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    border-bottom: 5px solid #fff;
    border-top: 5px solid #073855;
    height: 206px;
    width: 260px;
    margin: 0;
    overflow: hidden;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .property {
    margin-bottom: 0;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .property:hover img {
    top: 0;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content .property .overlay {
    bottom: 0px;
}

.infobox-wrapper>img {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: absolute !important;
    right: 8px;
    top: 13px;
    z-index: 2;
}

.infobox-wrapper>img:hover {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.infobox-wrapper .infobox-inner {
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-top: 6px solid #073855;
    font-size: 12px;
    position: relative;
    margin-bottom: 50px;
    min-width: 200px;
}

.infobox-wrapper .infobox-inner:after {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    left: 95px;
    content: '';
    bottom: -6px;
}

.infobox-wrapper .infobox-inner .infobox-image {
    width: 250px;
    height: auto;
    overflow: hidden;
    position: relative;
}

.infobox-wrapper .infobox-inner .infobox-image:after {
    background: -moz-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.75) 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(50%, transparent), color-stop(100%, rgba(0, 0, 0, 0.75)));
    background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.75) 100%);
    background: -o-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.75) 100%);
    background: -ms-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.75) 100%);
    background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.75) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.infobox-wrapper .infobox-inner .infobox-image img {
    width: 100%;
}

.infobox-wrapper .infobox-inner .infobox-image .infobox-price {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #1396e2;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 3px 7px;
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: 2;
}

.infobox-wrapper .infobox-inner .infobox-title {
    font-size: 14px;
    font-weight: bold;
}

.infobox-wrapper .infobox-inner .infobox-title a {
    color: #073855;
}

.infobox-wrapper .infobox-inner .infobox-description {
    padding: 8px;
}

.infobox-wrapper .infobox-inner .infobox-location {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    font-size: 12px;
}

.infobox-wrapper .infobox-inner .fas {
    color: #1396e2;
    float: right;
    font-size: 18px;
    margin-top: 4px;
}

.marker-hover {
    opacity: 0.5;
}

.search-box-wrapper {
    position: absolute;
    width: 100%;
    top: 0px;
}

.search-box-wrapper .search-box-inner h2 {
    margin-bottom: 20px;
}

#submit-map {
    margin-bottom: 20px;
}

.fade-map {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
}

.map-osm #map {
    z-index: 0;
    position: relative;
}

.marker-cluster {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #fff;
    width: 32px;
    height: 38px;
    background-color: #1396e2;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 38px;
}

.marker-cluster:hover {
    background-color: #073855;
}

.form-group {
    margin-bottom: 10px;
}

.tool-tip-bubble+.tooltip>.tooltip-inner {
    background-color: rgb(171, 171, 171);
}

.tooltip.in {
    opacity: 1 !important;
}

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='date'],
textarea.form-control {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2) inset;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2) inset;
    -webkit-appearance: none;
    background-color: #f3f3f3;
    border: none;
    font-size: 14px;
    outline: none !important;
    padding: 10px 9px 11px 9px;
    width: 100%;
    height: inherit;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
    line-height: 20px;
}

input[type='text']:active,
input[type='text']:focus,
input[type='email']:active,
input[type='email']:focus,
input[type='search']:active,
input[type='search']:focus,
input[type='password']:active,
input[type='password']:focus,
input[type='number']:active,
input[type='number']:focus,
input[type='tel']:active,
input[type='tel']:focus,
textarea.form-control:active,
textarea.form-control:focus {
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
}

input[type='text']:hover,
input[type='email']:hover,
input[type='search']:hover,
input[type='password']:hover,
input[type='number']:hover,
input[type='tel']:hover,
textarea.form-control:hover {
    background-color: rgba(0, 0, 0, 0.02);
    background-color: ##f3f3f3 !important;
}

.btn,
select {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    border: none;
    color: #5a5a5a;
    font-size: 14px;
    outline: none !important;
    padding: 10px 9px 9px 9px;
    width: auto;
}

.btn:hover,
select:hover {
    background-color: #ebebeb;
    color: #5a5a5a;
}

.btn:active,
.btn:focus,
select:active,
select:focus {
    -moz-box-shadow: 0px 1px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
    background-color: #ebebeb;
    color: #5a5a5a;
}

.btn.btn-default,
select.btn-default {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background-color: #1396e2;
    color: #fff;
}

.btn.btn-default:hover,
select.btn-default:hover {
    /* background-color: #128dd4; */
}

.btn.btn-default:active,
select.btn-default:active {
    -moz-box-shadow: 0px 2px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 2px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px transparent, inset 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.btn.btn-grey-dark,
select.btn-grey-dark {
    background-color: #2a2a2a;
    color: #fff;
}

.btn.btn-grey-dark:hover,
select.btn-grey-dark:hover {
    background-color: #393939;
}

.btn.btn-danger,
select.btn-danger {
    background-color: #c9302c;
    color: #fff;
}

.btn.btn-danger:hover,
select.btn-danger:hover {
    background-color: #b42b27;
}

.btn.btn-success,
select.btn-success {
    background-color: #5cb85c;
    color: #fff;
}

.btn.btn-success.express-btn {
    width: 100%;
}

.express-btn-txt {
    text-align: center;
    width: 100%;
}

.btn.btn-success:hover,
select.btn-success:hover {
    background-color: #4cae4c;
}

.btn.btn-info,
select.btn-info {
    background-color: #31b0d5;
    color: #fff;
}

.btn.btn-info:hover,
select.btn-info:hover {
    background-color: #28a1c4;
}

.btn.btn-warning,
select.btn-warning {
    background-color: #ec971f;
    color: #fff;
}

.btn.btn-warning:hover,
select.btn-warning:hover {
    background-color: #df8a13;
}

.btn.small,
select.small {
    font-size: 12px;
    padding: 5px 12px 5px;
}

.btn.large,
select.large {
    font-size: 18px;
    padding: 15px 20px;
}

.btn-transparent {
    background-color: transparent;
    border: none !important;
}

.transparent {
    background-color: transparent;
    border: none !important;
}

.checkbox,
.radio {
    padding-left: 0px;
}

.checkbox.switch {
    margin: 0;
    padding: 0;
    z-index: 2;
}

.checkbox.switch .icheckbox {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 2px solid #cdcdcd;
    background-color: #fff;
    display: inline-block;
    height: 25px;
    margin-left: 10px;
    position: relative;
    width: 45px;
    top: -1px;
}

.checkbox.switch .icheckbox:hover {
    border-color: #1396e2;
}

.checkbox.switch .icheckbox:hover:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.checkbox.switch .icheckbox:after {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #073855;
    content: '\f00c';
    font-size: 11px;
    position: absolute;
    left: 5px;
    line-height: 20px;
}

.checkbox.switch .icheckbox:before {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #1396e2;
    content: '';
    height: 17px;
    width: 17px;
    position: absolute;
    left: 2px;
    top: 2px;
}

.checkbox.switch .icheckbox.checked {
    border-color: #1396e2;
}

.checkbox.switch .icheckbox.checked:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: #073855;
    left: 22px;
}

.checkbox .icheckbox {
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-color: #f3f3f3;
    display: inline-block;
    height: 20px;
    margin-right: 10px;
    position: relative;
    width: 20px;
    top: -1px;
}

.checkbox .icheckbox:after {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #2a2a2a;
    content: '\f00c';
    position: absolute;
    line-height: 20px;
    left: 4px;
}

.checkbox .icheckbox.checked:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.iradio {
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-color: #f3f3f3;
    height: 20px;
    margin-right: 10px;
    position: relative;
    width: 20px;
    top: -1px;
}

.iradio:after {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    background-color: #2a2a2a;
}

.iradio.checked:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.input-group-addon {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
}

.input-group .search {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    width: 40px;
    z-index: 2;
}

.form-group {
    position: relative;
}

form #form-status {
    line-height: 1;
    position: relative;
    top: -28px;
}

form #form-status #valid {
    position: relative;
    top: -10px;
}

form #form-status #valid .icon {
    font-size: 24px;
    margin-right: 10px;
    vertical-align: middle;
}

form #form-status #invalid {
    color: red;
    position: relative;
    top: -10px;
}

form label.error {
    background-color: red;
    bottom: -30px;
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 5px;
    position: absolute;
    z-index: 2;
}

form label.error:before {
    border-style: solid;
    border-width: 0 3.5px 5px 3.5px;
    border-color: transparent transparent red transparent;
    content: '';
    height: 0px;
    left: 5px;
    position: absolute;
    top: -5px;
    width: 0px;
}

.form-submit .file-input {
    border: 2px solid #f3f3f3;
    padding: 15px;
    position: relative;
    width: 100%;
}

.form-submit .file-input .file-preview {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    padding: 0;
    margin-bottom: 20px;
}

.form-submit .file-input .file-preview .close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.form-submit .file-input .file-preview .file-preview-frame {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    border: none;
    height: inherit;
}

.form-submit .file-input .file-preview .file-preview-frame .file-preview-image {
    height: 100px;
}

.bootstrap-select {
    width: 100% !important;
    margin-bottom: 0 !important;
}

.bootstrap-select .selectpicker {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    color: #5a5a5a;
}

.bootstrap-select .selectpicker:focus,
.bootstrap-select .selectpicker:active {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    outline: none !important;
}

.bootstrap-select .selectpicker:hover {
    background-color: #ebebeb;
}

.bootstrap-select .selectpicker .caret {
    border: none;
}

.bootstrap-select .selectpicker .caret:after {
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    color: #1396e2;
    content: '\f107';
    font-size: 18px;
    position: absolute;
    top: -7px;
    right: -2px;
}

.bootstrap-select .selectpicker .filter-option {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding-left: 0px;
    position: relative;
}

.bootstrap-select .selectpicker .filter-option:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    speak: none;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #1396e2;
    content: '\f00c';
    font-size: 14px;
    position: absolute;
    left: 0px;
    top: 2px;
}

#form-map-sale button.dropdown-toggle {
    background-color: #f3f3f3;
    color: #5a5a5a;
}

.bootstrap-select .dropdown-toggle {
    background-color: #f3f3f3;
    color: #5a5a5a;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    bottom: -5px;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    border: none;
}

.bootstrap-select .dropdown-menu {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    margin-top: 3px;
    padding: 0;
    padding-left: 0 !important;
}

.bootstrap-select .dropdown-menu.selectpicker {
    background-color: #fff !important;
}

.bootstrap-select .dropdown-menu,
.bootstrap-select .dropdown-menu li.selected a {
    color: #939393;
    padding-left: 15px;
}

.bootstrap-select .dropdown-menu a:hover,
.bootstrap-select .dropdown-menu li.selected a:hover {
    background-color: transparent;
    padding-left: 15px;
}

.bootstrap-select .dropdown-menu li a {
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #5a5a5a;
    padding: 8px 15px;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus.selected {
    background-color: #e3e3e3;
    color: #333;
}

.bootstrap-select .dropdown-menu li a:hover {
    background-color: #f3f3f3;
    padding-left: 20px;
}

.bootstrap-select.open .selectpicker {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.form-search .bootstrap-select .selectpicker {
    font-weight: bold;
}

.selected-option-check-a {
    padding-left: 20px;
}

.selected-option-check-a:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.selected-option-check .selectpicker .filter-option {
    padding-left: 20px;
}

.selected-option-check .selectpicker .filter-option:before {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.jslider {
    font-family: 'Roboto', sans-serif;
    top: 12px;
}

.jslider .jslider-value {
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.jslider .jslider-bg i {
    height: 2px;
}

.jslider .jslider-bg .l {
    background: none;
    background-color: #fff;
}

.jslider .jslider-bg .f {
    background: none;
    background-color: #fff;
}

.jslider .jslider-bg .r {
    background: none;
    background-color: #fff;
}

.jslider .jslider-bg .v {
    background: none;
    background-color: #1396e2;
    height: 2px;
}

.jslider .jslider-pointer {
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background: url('../images/price-range-dragger.png') no-repeat 50% 50% #1396e2;
    height: 14px;
    width: 14px;
    top: 12px;
}

.jslider .jslider-pointer:before {
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent #1396e2 transparent;
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    top: -8px;
    left: 0;
}

.jslider .jslider-label {
    color: #fff;
}

.price-range {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    margin-bottom: 20px;
    padding: 15px 15px 10px;
}

.price-range .jslider-label,
.price-range .jslider-value {
    color: #5a5a5a;
}

.search-box-wrapper {
    z-index: 100;
}

.search-box {
    padding-right: 15px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.search-box.show-search-box {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.search-box h2 {
    border: none;
    padding-bottom: 0;
}

.search-box .btn {
    width: 100%;
}

.search-box .nav-pills li a {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent;
    color: #2a2a2a;
}

.search-box .form-map {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 5px 10px 1px 10px;
}

.search-box .form-map:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.search-box .form-map input[type='text'],
.search-box .form-map input[type='email'],
.search-box .form-map input[type='search'],
.search-box .form-map input[type='password'],
.search-box .form-map input[type='number'],
.search-box .form-map textarea,
.search-box .form-map select,
.search-box .form-map .selectpicker,
.search-box .form-map .price-range {
    background-color: #073855;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    min-height: 40px;
    padding: 10px 9px 9px 9px;
    width: 100%;
}

.search-box .form-map input[type='text']:hover,
.search-box .form-map input[type='email']:hover,
.search-box .form-map input[type='search']:hover,
.search-box .form-map input[type='password']:hover,
.search-box .form-map input[type='number']:hover,
.search-box .form-map textarea:hover,
.search-box .form-map select:hover,
.search-box .form-map .selectpicker:hover,
.search-box .form-map .price-range:hover {
    background-color: #062f47;
}

.search-box .form-map .selectpicker .caret:after {
    color: #fff;
}

.search-box .form-map .price-range {
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 15px 15px 10px;
}

.search-box .form-map .jslider-label,
.search-box .form-map .jslider-value {
    color: #fff;
}

.horizontal-search .search-box-wrapper {
    padding: 20px 0;
    position: relative !important;
    top: inherit !important;
    background-color: #1396e2;
}

.horizontal-search .search-box-wrapper .search-box {
    padding-right: 0;
    position: relative;
}

.horizontal-search .search-box-wrapper .search-box hr {
    margin-bottom: 10px;
    margin-top: 10px;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search-toggle {
    padding: 10px 0px;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search-toggle .fas {
    color: #073855;
    margin-left: 5px;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li.active a {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: #073855;
    color: #fff;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li.active a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li a {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #fff;
    position: relative;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li a:after {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 0;
    border-style: solid;
    border-width: 5px 3.5px 0 3.5px;
    border-color: #073855 transparent transparent transparent;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -5px;
}

.horizontal-search .search-box-wrapper .search-box .form-map {
    background-color: transparent;
    padding: 0;
    margin-top: 20px;
}

.horizontal-search .search-box-wrapper .search-box .form-map input[type='text'],
.horizontal-search .search-box-wrapper .search-box .form-map input[type='email'],
.horizontal-search .search-box-wrapper .search-box .form-map input[type='search'],
.horizontal-search .search-box-wrapper .search-box .form-map input[type='password'],
.horizontal-search .search-box-wrapper .search-box .form-map input[type='number'],
.horizontal-search .search-box-wrapper .search-box .form-map textarea,
.horizontal-search .search-box-wrapper .search-box .form-map select,
.horizontal-search .search-box-wrapper .search-box .form-map .selectpicker,
.horizontal-search .search-box-wrapper .search-box .form-map .price-range {
    background-color: #fff;
    color: #5a5a5a;
}

.horizontal-search .search-box-wrapper .search-box .form-map input[type='text']:hover,
.horizontal-search .search-box-wrapper .search-box .form-map input[type='email']:hover,
.horizontal-search .search-box-wrapper .search-box .form-map input[type='search']:hover,
.horizontal-search .search-box-wrapper .search-box .form-map input[type='password']:hover,
.horizontal-search .search-box-wrapper .search-box .form-map input[type='number']:hover,
.horizontal-search .search-box-wrapper .search-box .form-map textarea:hover,
.horizontal-search .search-box-wrapper .search-box .form-map select:hover,
.horizontal-search .search-box-wrapper .search-box .form-map .selectpicker:hover,
.horizontal-search .search-box-wrapper .search-box .form-map .price-range:hover {
    background-color: #f3f3f3;
}

.horizontal-search .search-box-wrapper .search-box .form-map button {
    background-color: #073855;
}

.horizontal-search .search-box-wrapper .search-box .form-map .selectpicker .caret:after {
    color: #1396e2;
}

.horizontal-search .search-box-wrapper .search-box .form-map .jslider-label,
.horizontal-search .search-box-wrapper .search-box .form-map .jslider-value {
    color: #5a5a5a;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search {
    padding-bottom: 20px;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search h3 {
    margin-top: 0;
}

.horizontal-search-float .search-box {
    padding-right: 0;
    position: inherit;
}

.horizontal-search-float .search-box .nav-pills li.active a {
    filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
    color: #073855;
}

.horizontal-search-float .search-box .nav-pills li a {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: rgba(255, 255, 255, 0.9);
    color: #5a5a5a;
}

.horizontal-search-float .search-box .form-map {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px 15px 5px 15px;
}

.horizontal-search-float .search-box .form-map:hover {
    background-color: white;
}

.horizontal-search-float .search-box .form-map input[type='text'],
.horizontal-search-float .search-box .form-map input[type='email'],
.horizontal-search-float .search-box .form-map input[type='search'],
.horizontal-search-float .search-box .form-map input[type='password'],
.horizontal-search-float .search-box .form-map input[type='number'],
.horizontal-search-float .search-box .form-map textarea,
.horizontal-search-float .search-box .form-map select,
.horizontal-search-float .search-box .form-map .selectpicker,
.horizontal-search-float .search-box .form-map .price-range {
    background-color: #073855;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    min-height: 40px;
    padding: inherit;
    padding-left: 10px;
    width: 100%;
}

.horizontal-search-float .search-box .form-map input[type='text']:hover,
.horizontal-search-float .search-box .form-map input[type='email']:hover,
.horizontal-search-float .search-box .form-map input[type='search']:hover,
.horizontal-search-float .search-box .form-map input[type='password']:hover,
.horizontal-search-float .search-box .form-map input[type='number']:hover,
.horizontal-search-float .search-box .form-map textarea:hover,
.horizontal-search-float .search-box .form-map select:hover,
.horizontal-search-float .search-box .form-map .selectpicker:hover,
.horizontal-search-float .search-box .form-map .price-range:hover {
    background-color: #062f47;
}

.horizontal-search-float .search-box .form-map .price-range {
    margin-bottom: 0;
    padding: 15px 15px 10px;
}

.horizontal-search-float .search-box .form-map .jslider-label,
.horizontal-search-float .search-box .form-map .jslider-value {
    color: #fff;
}

.animation-fade-in {
    -webkit-animation-name: animation-fade-in;
    -moz-animation-name: animation-fade-in;
    -ms-animation-name: animation-fade-in;
    animation-name: animation-fade-in;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    animation-duration: 0.2s;
}

@-webkit-keyframes animation-fade-in {}

@-moz-keyframes animation-fade-in {}

@-ms-keyframes animation-fade-in {}

@keyframes animation-fade-in {}

@-webkit-keyframes animation-fade-in {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
}

@-moz-keyframes animation-fade-in {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
}

@-ms-keyframes animation-fade-in {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
}

@keyframes animation-fade-in {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
}

.animation-fade-out {
    -webkit-animation-name: animation-fade-out;
    -moz-animation-name: animation-fade-out;
    -ms-animation-name: animation-fade-out;
    animation-name: animation-fade-out;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    animation-duration: 0.2s;
}

@-webkit-keyframes animation-fade-out {}

@-moz-keyframes animation-fade-out {}

@-ms-keyframes animation-fade-out {}

@keyframes animation-fade-out {}

@-webkit-keyframes animation-fade-out {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes animation-fade-out {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes animation-fade-out {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes animation-fade-out {
    0% {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    100% {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

body,
html {
    height: 100%;
    margin: 0;
}

.wrapper {
    display: table;
    height: 100%;
    width: 100%;
}

#page-content {
    height: auto;
}

#page-footer {
    height: 1px;
}

.row-divider {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-top: 20px;
    padding-bottom: 10px;
}

.setting-subheader {
    margin-top: 0;
    font-size: 18px;
    font-weight: lighter;
    margin-bottom: 20px;
}


/* 7. Responsive */

@media (min-width: 1200px) {
    .property .property-image-slide {
        height: 100% !important;
    }
    .property .property-image {
        height: 190px;
    }
    .page-homepage .row:last-child .property {
        margin-bottom: 0;
    }
    .page-homepage .row:last-child .feature-box {
        margin-bottom: 0;
    }
    #slider .slide {
        overflow: hidden;
    }
    #slider .slide img {
        position: relative !important;
        width: inherit !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .member {
        padding-left: 110px;
    }
    .member h3 {
        font-size: 14px;
    }
    .member dl {
        font-size: 12px;
    }
    .member .image img {
        width: 90px;
    }
    .property.masonry {
        width: 31.9%;
    }
    .property.big .overlay {
        bottom: -50px;
    }
    .property.big .overlay h3 {
        font-size: 18px;
    }
    .property.big .overlay li header,
    .property.big .overlay li figure {
        font-size: 12px;
    }
    .property.big .overlay .additional-info {
        height: 50px;
        padding: 8px 15px;
    }
    .property.big .overlay .price {
        font-size: 14px;
    }
    .property.big .overlay .info {
        padding: 15px;
    }
    .property .property-image {
        height: 150px;
    }
    .ribbon {
        width: 120px;
        height: 25px;
        bottom: inherit;
        font-size: 11px;
        padding: 4px;
    }
    #slider .slide {
        overflow: hidden;
    }
    #slider .slide img {
        position: relative !important;
        height: 100% !important;
        width: inherit !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .agency address {
        margin-left: 10px;
        width: 40%;
    }
    .agency .agency-image {
        width: 30%;
    }
    .agency .agency-image img {
        max-width: 140px;
    }
    .agency .wrapper {
        width: inherit;
    }
    .agent-info {
        margin-bottom: 30px;
    }
    .col-sm-4 .property {
        border: 2px solid #f3f3f3;
    }
    .col-sm-4 .property:hover img {
        top: 0;
    }
    .col-sm-4 .property .additional-info {
        display: none;
    }
    .col-sm-4 .property .overlay {
        bottom: 0;
        position: relative;
    }
    .col-sm-4 .property .overlay .info {
        background: none;
    }
    .col-sm-4 .property .overlay .info h3 {
        text-shadow: none;
        color: #073855;
    }
    .col-sm-4 .property .overlay .info figure {
        color: #5a5a5a;
        font-size: 12px;
    }
    .col-sm-4 .property .overlay .info .tag {
        margin-bottom: 5px;
    }
    .display-lines .property {
        padding-left: 220px;
    }
    .display-lines .property .info h3 {
        font-size: 24px !important;
    }
    .display-lines .property .property-image {
        height: 150px;
        width: 200px;
    }
    .member {
        margin-bottom: 40px !important;
        padding-left: 60px;
    }
    .member h3 {
        font-size: 14px;
    }
    .member dl {
        font-size: 10px;
    }
    .member .image img {
        width: 50px;
    }
    .property .property-image {
        height: 100px;
    }
    .property.big .overlay {
        bottom: -50px;
    }
    .property.big .overlay h3 {
        font-size: 18px;
    }
    .property.big .overlay li header,
    .property.big .overlay li figure {
        font-size: 11px;
    }
    .property.big .overlay .additional-info {
        height: 50px;
        padding: 10px 15px;
    }
    .property.big .overlay .price {
        font-size: 14px;
    }
    .property.big .overlay .info {
        padding: 15px;
    }
    .property.masonry {
        width: 31.45%;
    }
    .property.small .info {
        padding-left: 0;
    }
    .property.small .property-image {
        float: none;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    .ribbon {
        right: -20px;
        width: 80px;
        height: 18px;
        bottom: inherit;
        font-size: 10px;
        padding: 2px;
    }
    .sidebar-navigation li {
        text-align: center;
        padding: 10px 0;
    }
    .sidebar-navigation li a i {
        font-size: 18px;
    }
    .sidebar-navigation li a span {
        display: none;
    }
    #slider .slide {
        overflow: hidden;
    }
    #slider .slide img {
        position: relative !important;
        height: 100% !important;
        width: inherit !important;
    }
    .submit-features li {
        width: 49%;
    }
    .text-banner h1 {
        font-size: 24px;
        margin-top: 0px;
        padding-bottom: 0px;
    }
    .universal-button {
        font-size: 12px;
        padding: 15px;
    }
    .universal-button figure {
        font-size: 20px;
        width: 30px;
    }
}

@media (max-width: 767px) {
    .carousel {
        font-size: 9px;
    }
    .carousel h1 {
        font-size: 18px !important;
        padding-bottom: 4px;
        margin-bottom: 4px;
    }
    .carousel h2 {
        font-size: 12px !important;
    }
    .carousel dt {
        line-height: 1;
    }
    .carousel dd {
        line-height: 1;
    }
    .carousel hr {
        display: none;
    }
    .carousel .teaserHeadline {
        font-size: 12px;
        text-align: center;
        border-bottom: 0px;
        margin-bottom: 5px;
    }
    .joyride-tooltip.top {
        top: 200px !important;
    }
    .joyride-tooltip__triangle {
        display: none;
    }
    .deadline {
        float: none !important;
    }
    .hovereffect {
        background: #6ec2ee;
    }
    .hovereffect a {
        opacity: 1;
        color: #fff;
    }
    .hovereffect img {
        opacity: 0.4;
    }
    .goals .goals-side {
        text-align: left;
    }
    .goals .current-goals-side {
        height: 60px;
    }
    .agency .agency-image {
        display: block;
        width: inherit;
    }
    .agency .agency-image img {
        max-width: 100%;
    }
    .agency .wrapper {
        display: block;
        width: inherit;
    }
    .agency .wrapper dl,
    .agency .wrapper address {
        display: block;
        width: inherit;
        margin-left: 0;
    }
    .agency .wrapper dl {
        margin-bottom: 30px;
    }
    .agency-image {
        text-align: center;
        margin-bottom: 30px;
    }
    .agent {
        padding-left: 0;
        text-align: center;
    }
    .agent .agent-image {
        display: inline-block;
        position: relative;
        text-align: center;
        max-width: 100%;
        margin-bottom: 20px;
        width: inherit !important;
    }
    .agent .agent-image img {
        width: inherit !important;
    }
    blockquote {
        display: block !important;
        padding: 20px 0 !important;
        text-align: center;
    }
    blockquote figure {
        display: inline-block !important;
        margin-bottom: 30px;
    }
    blockquote .cite {
        display: block !important;
        padding-left: 0;
    }
    .blog-post {
        margin-bottom: 30px;
    }
    .blog-post h2 {
        font-size: 24px !important;
    }
    .blog-post .tags {
        float: none !important;
    }
    .blog-post .tags .tag {
        margin: 20px 0;
    }
    .blog-post .tags .tag:first-child {
        margin-left: 0 !important;
    }
    .account-profile h3 {
        margin-bottom: 15px;
    }
    .agent-detail h3 {
        margin-top: 40px;
    }
    .agent-info {
        padding-left: 0 !important;
        margin-bottom: 20px;
    }
    .agent-info figure {
        text-align: center;
        position: relative !important;
        margin-bottom: 20px;
    }
    .background-image {
        left: -200%;
        margin-left: 100%;
    }
    .block {
        padding: 10px 0;
    }
    .banner {
        padding: 20px;
    }
    .banner .title {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
    }
    .banner .submit {
        display: block;
        text-align: center;
        width: 100%;
    }
    .banner .continuity-submit {
        display: block;
        text-align: center;
        width: 100%;
    }
    .comment .name,
    .comment .date {
        float: none !important;
        position: relative !important;
        top: 0 !important;
    }
    .display-lines .property {
        padding-left: 0px;
    }
    .display-lines .property .info a {
        position: relative !important;
    }
    .display-lines .property .info aside p,
    .display-lines .property .info aside dl {
        float: none;
        width: 100%;
    }
    .display-lines .property .property-image {
        height: auto;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
    }
    .feature-box {
        margin-bottom: 30px !important;
    }
    .form-map {
        background-color: transparent !important;
        padding: 0 !important;
    }
    .fun-facts {
        padding: 0;
    }
    .fun-facts:before {
        display: none;
    }
    .fun-facts .number-wrapper {
        margin-bottom: 30px;
        position: relative;
    }
    .fun-facts .number-wrapper:before {
        background-color: rgba(0, 0, 0, 0.07);
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        top: -15px;
        right: 0;
        width: 100%;
        z-index: -2;
    }
    .geo-location-wrapper .btn {
        top: 20px;
    }
    .horizontal-search .advanced-search-toggle {
        position: relative !important;
    }
    .horizontal-search .search-box-wrapper input[type='text'],
    .horizontal-search .search-box-wrapper input[type='email'],
    .horizontal-search .search-box-wrapper input[type='search'],
    .horizontal-search .search-box-wrapper input[type='password'],
    .horizontal-search .search-box-wrapper input[type='number'],
    .horizontal-search .search-box-wrapper textarea.form-control,
    .horizontal-search .search-box-wrapper .selectpicker {
        background-color: #fff !important;
    }
    .horizontal-search .submit-features li {
        width: 100%;
    }
    .bucket-a .mobile,
    .bucket-breadcrumb .mobile {
        display: block !important;
        text-transform: none !important;
    }
    .bucket-a-inner-copy .desktop,
    .desktop {
        display: none !important;
    }
    .logos {
        padding: 0;
        text-align: center;
    }
    .logos .logo {
        display: block;
        margin-bottom: 40px;
    }
    .logos .logo:last-child {
        margin-bottom: 0;
    }
    .floor-plans img {
        width: 100%;
    }
    #footer-copyright {
        text-align: center;
    }
    #footer-copyright span {
        float: none !important;
    }
    .leaflet-control-attribution {
        display: none;
    }
    .map-osm #map:after {
        display: none;
    }
    #map {
        margin-top: 0 !important;
    }
    #map:after {
        -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        text-shadow: none;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        speak: none;
        font-variant: normal;
        line-height: 1;
        text-transform: none;
        color: #2a2a2a;
        font-size: 18px;
        position: absolute;
        bottom: 30px;
        background-color: #fff;
        content: '\f07d';
        padding: 12px 16px;
        right: 15px;
        z-index: 1;
    }
    .member {
        margin-bottom: 40px !important;
        padding-left: 0px;
        text-align: center;
    }
    .member h3 {
        font-size: 18px;
    }
    .member dl {
        border-top: none;
        border-bottom: 2px solid #f3f3f3;
        font-size: 12px;
        padding-bottom: 20px;
    }
    .member .image {
        display: block;
        position: relative;
    }
    .member .image img {
        width: 150px;
    }
    .member .tag {
        top: 10px;
    }
    .navigation {
        position: relative !important;
        max-height: 112px;
    }
    .navigation .container {
        margin-right: 0;
        margin-left: 0;
    }
    .navigation .navbar {
        display: flex;
        padding: 12px;
    }
    .navigation .navbar-header {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        width: 100%;
        padding: 0 12px;
    }
    .navigation .navbar-header .navbar-brand {
        padding: 0 12px;
        flex: 0 0 100%;
        display: flex;
        margin: auto;
    }
    .navigation .navbar-header .navbar-brand .logo {
        margin: auto;
    }
    .navigation .navbar .navbar-nav {
        background: white;
    }
    .navigation .navbar .navbar-collapse {
        border-top: none;
        max-height: inherit;
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;
        padding-right: 15px;
        padding-left: 15px;
        position: absolute;
        right: 4px;
        top: 60px;
        border: 1px solid #dadada;
    }
    .navigation .navbar .collapse {
        display: none !important;
        float: inherit !important;
    }
    .navigation .navbar .collapse.in {
        display: block !important;
    }
    .navigation .navbar .collapse.in .child-navigation {
        display: block;
    }
    .navigation .navbar .navbar-nav {
        margin-bottom: 0;
        float: none !important;
    }
    .navigation .navbar .navbar-nav li:hover>.child-navigation {
        height: auto;
    }
    .navigation .navbar .navbar-nav li:hover>.child-navigation.navigation-to-left {
        right: inherit;
    }
    .navigation .navbar .navbar-nav li:hover>.child-navigation li:hover>.child-navigation {
        -moz-transition-delay: 0;
        -webkit-transition-delay: 0;
        transition-delay: 0;
        height: auto;
    }
    .navigation .navbar .navbar-nav li.active a:after {
        display: none;
    }
    .navigation .navbar .navbar-nav li.has-child:after {
        right: 25px !important;
    }
    .navigation .navbar .navbar-nav li a {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .navigation .navbar .navbar-nav li span {
        padding: 6px 8px;
    }
    .navigation .user_menu {
        display: block;
    }
    .navigation .u_menu {
        margin-left: -4px;
        display: block;
    }
    .navigation .navbar .navbar-nav li.mobile-submit {
        display: block;
    }
    .navigation .navbar .navbar-nav li.mobile-submit i {
        color: #1396e2;
        position: absolute;
        bottom: 12px;
        margin-left: 10px;
    }
    .navigation .navbar .navbar-nav li .child-navigation {
        left: 0;
        min-width: inherit;
        width: 100%;
        position: relative;
        margin-top: 0;
        box-shadow: none;
        background-color: #f3f3f3;
        height: 0;
    }
    .navigation .navbar .navbar-nav li .child-navigation li {
        border-bottom: none !important;
    }
    .navigation .navbar .navbar-nav li .child-navigation li:first-child a:after,
    .navigation .navbar .navbar-nav li .child-navigation li:last-child a:after {
        display: none !important;
    }
    .navigation .navbar .navbar-nav li .child-navigation li a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 15px;
        padding: 20px 15px 20px 15px;
        width: 100%;
    }
    .navigation .navbar .navbar-nav li .child-navigation li a:hover {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        color: #1396e2;
    }
    .navigation .navbar .navbar-nav li .child-navigation li:hover .child-navigation {
        height: auto;
    }
    .navigation .navbar .navbar-nav li .child-navigation li .child-navigation {
        -moz-transition-delay: 0;
        -webkit-transition-delay: 0;
        transition-delay: 0;
        height: 0;
        background-color: #ebebeb;
        margin-top: 0;
        left: 0px !important;
    }
    .navigation .navbar .top-nav {
        display: none;
    }
    .navigation .navbar .navbar-toggle .icon-bar {
        background-color: #2a2a2a;
    }
    .navigation .secondary-navigation {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
        background-color: #073855;
    }
    .navigation .secondary-navigation .contact {
        display: none;
    }
    .navigation .secondary-navigation .user-area {
        float: none;
    }
    .navigation .secondary-navigation .user-area a {
        color: #fff;
        margin-left: 0;
        margin-right: 10px;
    }
    .navigation .secondary-navigation .user-area a:hover {
        color: #fff;
    }
    .owl-controls {
        display: none !important;
    }
    #page-content {
        padding-top: 0px !important;
    }
    #page-footer #footer-main {
        padding-bottom: 0 !important;
    }
    #page-footer #footer-main article {
        margin-bottom: 50px;
    }
    #page-footer #footer-main article h3 {
        margin-bottom: 10px !important;
    }
    .page-sign-in .wrapper {
        display: table !important;
    }
    #page-content {
        overflow: hidden;
        width: 100%;
    }
    .add-your-property {
        display: none;
        bottom: inherit;
        position: absolute;
        top: 8px;
    }
    .add-your-property figure {
        display: none !important;
    }
    .post-author {
        text-align: center;
    }
    .post-author img {
        display: inline-block;
        margin-bottom: 20px;
    }
    .post-author .wrapper {
        display: block;
    }
    .property {
        margin-bottom: 30px !important;
    }
    .property.small .info {
        padding-left: 0;
    }
    .property.small .property-image {
        float: none;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    .property.masonry {
        width: 100%;
    }
    .property.masonry .property-image:hover img {
        -moz-transform: none;
        -webkit-transform: none;
        transform: none;
    }
    .property.masonry .property-image img {
        -moz-backface-visibility: visible;
        -webkit-backface-visibility: visible;
        backface-visibility: visible;
        -moz-transform: none;
        -webkit-transform: none;
        transform: none;
    }
    .property-detail-map {
        width: 100% !important;
    }
    .property-features-list li {
        width: 100%;
    }
    .property-title .actions {
        position: relative;
        margin-top: 20px;
        top: 10px;
    }
    .property-title .actions .title-add,
    .property-title .actions .title-added {
        text-align: left;
        left: 25px;
    }
    .rating-form .btn {
        float: none !important;
        width: 100%;
    }
    #search-filter {
        margin-bottom: 10px;
        padding: 0;
    }
    #search-filter .sorting {
        position: relative;
        margin-top: 10px;
    }
    #search-filter .sorting .form-group {
        width: 100%;
    }
    .search-box-wrapper {
        background-color: #073855;
        padding: 20px 0;
        position: relative;
        top: 0 !important;
    }
    .search-box-wrapper h2 {
        color: #fff;
    }
    .search-box-wrapper input[type='text'],
    .search-box-wrapper input[type='email'],
    .search-box-wrapper input[type='search'],
    .search-box-wrapper input[type='password'],
    .search-box-wrapper input[type='number'],
    .search-box-wrapper textarea.form-control {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .search-box-wrapper .background-image {
        left: inherit;
        margin-left: inherit;
    }
    .search-box-wrapper .selectpicker,
    .search-box-wrapper .price-range {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .search-box-wrapper .search-box {
        margin-top: 0;
        padding-right: 0;
        position: relative;
    }
    .search-box-wrapper .search-box .nav-pills {
        margin-bottom: 20px;
    }
    .search-box-wrapper .search-box .nav-pills li.active a {
        background-color: #1396e2;
        color: #fff;
        position: relative;
    }
    .search-box-wrapper .search-box .nav-pills li.active a:after {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
    }
    .search-box-wrapper .search-box .nav-pills li a {
        filter: progid: DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
        background-color: transparent;
        color: #fff;
    }
    .search-box-wrapper .search-box .nav-pills li a:after {
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -moz-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 0;
        border-style: solid;
        border-width: 5px 3.5px 0 3.5px;
        border-color: #1396e2 transparent transparent transparent;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -5px;
    }
    .section-title .link-arrow {
        position: relative;
        top: -15px;
    }
    #slider {
        margin-top: 0 !important;
    }
    #slider .slide {
        overflow: hidden;
    }
    #slider .slide:after {
        height: 50% !important;
    }
    #slider .slide .overlay {
        margin-bottom: 10px !important;
    }
    #slider .slide .overlay figure {
        font-size: 14px !important;
    }
    #slider .slide .overlay h3 {
        font-size: 24px !important;
        font-weight: bold !important;
    }
    #slider .slide .overlay hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #slider .slide .overlay img {
        left: -200%;
        position: relative;
    }
    #slider .slide .overlay .tag {
        font-size: 18px !important;
    }
    #slider .slide img {
        position: relative !important;
        height: 100% !important;
        width: inherit !important;
    }
    .submit-step {
        display: none;
    }
    .submit-pricing table th {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .submit-pricing table th:first-child {
        padding-left: 0 !important;
    }
    .text-banner h1 {
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
        padding-bottom: 15px;
    }
    .form-submit section {
        margin-bottom: 30px;
    }
    .form-submit .submit-features li {
        width: 100%;
    }
    .form-submit .file-preview-frame {
        display: inline-block;
        float: none;
    }
    #video-presentation img {
        width: 100%;
    }
    .wrapper {
        display: block;
    }
}

@media print {
    #page-footer,
    .breadcrumb {
        display: none;
    }
    .page-property-detail body,
    .page-property-detail html {
        height: 100%;
        width: 100%;
        padding: 0;
    }
    .page-property-detail #sidebar,
    .page-property-detail #page-footer,
    .page-property-detail .thick,
    .page-property-detail .actions,
    .page-property-detail .navigation,
    .page-property-detail .breadcrumb {
        display: none;
    }
    .page-invoice .navigation {
        display: none;
    }
    .invoice {
        font-size: 12px;
    }
    .invoice address {
        line-height: 18px;
    }
    .invoice h1 {
        font-size: 36px;
        margin-top: 20px;
    }
    .invoice h2,
    .invoice h3 {
        font-size: 18px;
    }
    .invoice section {
        border-width: 1px;
        padding: 10px 0;
    }
    .invoice dl dd {
        margin-bottom: 4px;
    }
    .invoice #description-table th {
        border-bottom: 2px solid #e8e8e8;
        padding: 8px 0;
    }
    .invoice #description-table th:first-child {
        padding-left: 0px;
    }
    .invoice #description-table th:last-child {
        padding-right: 0px;
    }
    .invoice #description-table td {
        padding: 10px 0;
    }
    .invoice #description-table td:first-child {
        padding-left: 0px;
    }
    .invoice #description-table td:last-child {
        padding-right: 0px;
    }
}

@page {
    page-break-after: auto;
    margin: 0;
}


/*mail inbox*/

.mail-nav {
    margin: 15px -15px 0 -15px;
    padding: 0 10px;
}

.mail-nav li a {
    border-radius: 0;
    -webkit-border-radius: 0;
    border-top: 1px solid #f2f3f6;
    padding: 15px;
    border-right: 3px solid rgba(0, 0, 0, 0);
}

.mail-nav>li>a:hover,
.mail-nav>li>a:focus {
    background: #fafafa;
    color: #0f66c9;
    border-right: 3px solid #0f66c9;
}

.mail-nav>li.active>a,
.mail-nav>li.active>a:hover,
.mail-nav>li.active>a:focus {
    background: #fafafa;
    color: #0f66c9;
    border-right: 3px solid #0f66c9;
}

.mail-nav>li+li {
    margin-left: 0;
    margin-top: 0px;
}

.mail-nav i {
    font-size: 16px;
    margin-right: 10px;
}

.inbox-notification {
    margin-top: -2px;
}

.user-head .inbox-avatar {
    width: 65px;
    float: left;
}

.user-head .inbox-avatar img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.user-head .user-name {
    display: inline-block;
    margin: 0 0 0 10px;
}

.user-head .user-name h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: 300;
}

.user-head .user-name h5 a {
    color: #fff;
}

.user-head .user-name span a {
    font-size: 12px;
    color: #87e2e7;
}

a.mail-dropdown {
    background: #80d3d9;
    padding: 3px 5px;
    font-size: 10px;
    color: #01a7b3;
    border-radius: 2px;
    margin-top: 20px;
}

.inbox-body {
    padding: 20px;
}

.btn-compose {
    background: #0f66c9;
    padding: 12px 0;
    text-align: center;
    width: 100%;
    color: #fff;
}

.btn-compose:hover,
.btn-compose:focus {
    background: #8a8c98;
    color: #fff;
}

ul.inbox-nav {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.inbox-divider {
    border-bottom: 1px solid #d5d8df;
}

.inbox-action {
    margin-top: 50px;
}

.src-position {
    margin-top: -7px;
}

.mail-src-position {
    margin-top: -3px;
}

ul.labels-info {
    border-bottom: 1px solid #f1f2f7;
    margin-bottom: 10px;
}

ul.inbox-nav li {
    display: inline-block;
    line-height: 45px;
    width: 100%;
}

ul.inbox-nav li a {
    color: #6a6a6a;
    line-height: 45px;
    width: 100%;
    display: inline-block;
    padding: 0 20px;
}

ul.inbox-nav li a:hover,
ul.inbox-nav li.active a,
ul.inbox-nav li a:focus {
    color: #6a6a6a;
    background: #d5d7de;
}

ul.inbox-nav li a i {
    padding-right: 10px;
    font-size: 16px;
    color: #6a6a6a;
}

ul.inbox-nav li a span.label {
    margin-top: 13px;
}

ul.labels-info li h4 {
    padding-top: 5px;
    color: #5c5c5e;
    font-size: 15px;
    text-transform: uppercase;
}

ul.labels-info li {
    margin: 0;
}

ul.labels-info li a {
    color: #6a6a6a;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

ul.labels-info li a:hover,
ul.labels-info li a:focus {
    color: #6a6a6a;
    background: none;
}

ul.labels-info li a i {
    padding-right: 10px;
}

.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
}

.inbox-head {
    padding: 20px;
    background: #41cac0;
    color: #fff;
    border-radius: 0 4px 0 0;
    -webkit-border-radius: 0 4px 0 0;
    min-height: 80px;
}

.inbox-head h3 {
    margin: 0;
    display: inline-block;
    padding-top: 6px;
    font-weight: 300;
}

.inbox-head .sr-input {
    height: 40px;
    border: none;
    box-shadow: none;
    padding: 0 10px;
    float: left;
    border-radius: 4px 0 0 4px;
    color: #8a8a8a;
}

.inbox-head .sr-btn {
    height: 40px;
    border: none;
    background: #00a6b2;
    color: #fff;
    padding: 0 20px;
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
}

.wht-bg {
    background: #fff;
}

.wht-bg h4 {
    font-size: 24px;
    color: #a2a2a2;
    font-weight: 300;
}

.table-inbox-wrap {
    margin: 0 -15px;
}

.mail-checkbox {
    margin-right: 10px;
}

.table-inbox {
    margin: 0;
}

.table-inbox tr td {
    padding: 12px !important;
}

.table-inbox tr td:first-child {
    width: 5%;
}

.table-inbox tr td:first-child .icheckbox_minimal {
    margin-left: 15px;
}

.table-inbox tr td:hover {
    cursor: pointer;
}

.table-inbox tr td .fa-star.inbox-started,
.table-inbox tr td .fa-star:hover {
    color: #f78a09;
}

.table-inbox tr td .fa-star {
    color: #d5d5d5;
}

.table-inbox tr.unread td {
    font-weight: 600;
    background: #f7f7f7;
}

.table-inbox tr.unread a {
    color: #32323a;
}

.table-inbox tr a {
    color: #767676;
}

ul.inbox-pagination li {
    float: right;
}

ul.inbox-pagination li {
    float: left;
}

.pagination li a {
    color: #32323a;
}

.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

.mail-option .chk-all {
    float: left;
}

.mail-option .chk-all,
.mail-option .btn-group {
    margin-right: 5px;
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
    border: 1px solid #e7e7e7;
    padding: 5px 10px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.inbox-pagination a.np-btn {
    border: 1px solid #e7e7e7;
    padding: 5px 15px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.mail-option .chk-all input[type='checkbox'] {
    margin-top: 0;
}

.mail-option .btn-group a.all {
    padding: 0;
    border: none;
}

.inbox-pagination a.np-btn {
    margin-left: 5px;
}

.inbox-pagination li span {
    display: inline-block;
    margin-top: 7px;
    margin-right: 5px;
}

.fileinput-button {
    border: 1px solid #e6e6e6;
    background: #eeeeee;
}

.inbox-body .modal .modal-body input,
.inbox-body .modal .modal-body textarea {
    border: 1px solid #e6e6e6;
    box-shadow: none;
}

.btn-send,
.btn-send:hover {
    background: #00a8b3;
    color: #fff;
}

.btn-send:hover {
    background: #009da7;
}

.modal-header h4.modal-title {
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.modal-body label {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.compose-mail {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 20px;
}

.compose-mail .compose-options {
    color: #979797;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 7px;
}

.compose-mail input,
.compose-mail input:focus {
    border: none;
    padding: 0;
    width: 80%;
    float: left;
}

.compose-mail .form-group {
    border: 1px solid #f2f3f6;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.compose-mail .form-group label {
    line-height: 34px;
    width: 10%;
    float: left;
    padding-left: 5px;
    margin-bottom: 0;
}

.compose-editor input {
    margin-top: 15px;
}

.compose-editor {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.compose-btn {
    float: left;
    margin-top: 6px;
}

.mail-header h4 {
    font-weight: 300;
}

.mail-sender,
.attachment-mail {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    border-top: 1px solid #eff2f7;
    border-bottom: 1px solid #eff2f7;
    padding: 10px 0;
}

.mail-sender img {
    width: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.mail-sender .date {
    line-height: 30px;
    margin-bottom: 0;
    text-align: right;
}

.view-mail a,
.attachment-mail a:hover {
    color: #35bcb5;
}

.attachment-mail a {
    color: #32323a;
}

.attachment-mail ul li {
    float: left;
    width: 200px;
    margin-right: 15px;
    margin-top: 15px;
    list-style: none;
}

.attachment-mail ul li a.atch-thumb img {
    width: 200px;
    height: 180px;
    margin-bottom: 10px;
}

.attachment-mail ul li a.name span {
    float: right;
    color: #767676;
}

.white-popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 600px;
    margin: 20px auto;
}

.top-nav .username {
    font-size: 13px;
    color: #555555;
}

.top-nav ul.top-menu>li>a {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    padding: 0px;
    background: none;
    margin-right: 0;
    border: 1px solid #f6f6f6;
    background: #f6f6f6;
}

.top-nav ul.top-menu>li.language>a {
    margin-top: -2px;
    padding: 4px 12px;
    line-height: 20px;
}

.top-nav ul.top-menu>li.language>a img {
    border-radius: 0;
    -webkit-border-radius: 0;
    width: 18px;
}

.top-nav ul.top-menu>li.language ul.dropdown-menu li img {
    border-radius: 0;
    -webkit-border-radius: 0;
    width: 18px;
}

.top-nav ul.top-menu>li {
    margin-left: 10px;
}

.top-nav ul.top-menu>li>a:hover,
.top-nav ul.top-menu>li>a:focus {
    border: 1px solid #f6f6f6;
    background: #f6f6f6 !important;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.top-nav .dropdown-menu.extended.logout {
    top: 50px;
}

.top-nav .nav .caret {
    border-bottom-color: #a4aaba;
    border-top-color: #a4aaba;
}

.top-nav ul.top-menu>li>a:hover .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

ul.top-menu {
    margin-right: 15px;
    margin-top: 0;
}

ul.top-menu>li>a {
    color: #666666;
    font-size: 16px;
    background: #f6f6f6;
    padding: 4px 8px;
    margin-right: 15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    padding-right: 8px !important;
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}

.error-class {
    color: red;
}


/*date picker*/

.add-on {
    position: absolute;
    right: 39px;
    margin-top: -40px;
}

.add-on .btn {
    padding: 9px;
}

.daterangepicker .ranges .range_inputs>div:nth-child(2) {
    margin-bottom: 10px;
    padding-left: 0px;
}

.daterangepicker .ranges label {
    padding-bottom: 0;
    padding-top: 8px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.datepicker td.active:hover,
.datepicker td.active:hover:hover,
.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active.active:hover,
.datepicker td.active.disabled,
.datepicker td.active.disabled:hover,
.datepicker td.active[disabled],
.datepicker td.active[disabled]:hover,
.datepicker td span.active:hover,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
    background: #1e4eb6;
}

.daterangepicker .calendar th,
.daterangepicker .calendar td {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.datepicker td.active,
.datepicker td.active:hover,
.datepicker td span.active {
    text-shadow: none;
}

.datepicker th.switch {
    width: 125px;
}

.datepicker td span {
    height: 40px;
    line-height: 40px;
}

.bootstrap-timepicker table td input {
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}


/*gallery*/

.media-filter {
    float: left;
    margin: 10px 0;
    padding-left: 0;
}

.media-filter li {
    float: left;
    margin-right: 10px;
    list-style: none;
}

.media-filter li a {
    background: #e6e6e6;
    padding: 5px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.media-filter li a:hover,
.media-filter li a:focus {
    background: #0f66c9;
    color: #fff;
}

.filter-button {
    background: #e6e6e6;
    padding: 5px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.filter-button:hover,
.filter-button:focus {
    background: #0f66c9;
    color: #fff;
}

.filter-button.active {
    background-color: #666;
    color: white;
}

.media-gal {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.media-gal .item {
    float: left;
}

.media-gal .item {
    margin-bottom: 1%;
    margin-right: 1%;
    width: 233px;
    padding: 10px;
    border: 1px solid #ddd;
}

.media-gal .item p {
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;
}

.media-gal .item img {
    height: 200px;
    width: 100%;
}

.img-modal img {
    width: 100%;
    margin-bottom: 10px;
}

.profile-modal h1 {
    color: #0f66c9;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}


/* Start here */

.ribbon-price {
    position: relative;
    background: #1396e2;
    text-align: center;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -5px 10px -5px #333, 5px 0 10px -5px #333, -5px 0 10px -5px #333;
}

.ribbon-price h3 {
    margin: 0;
    color: #fff;
    font-size: 22px;
    text-transform: uppercase;
}

.ribbon-price i {
    margin-right: 10px;
}

.ribbon-price-border {
    margin: -2px;
}

.ribbon-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    top: -4px;
    right: -3px;
}

.ribbon-red {
    position: relative;
    top: 22px;
    left: 0;
    width: 130px;
    font-size: 13px;
    padding: 5px 0;
    color: #fff;
    background-color: #d02d2e;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.ribbon-red:before,
.ribbon-red:after {
    position: absolute;
    bottom: -3px;
    content: '';
    border-top: 3px solid #d02d2e;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

.ribbon-red:before {
    left: 0;
}

.ribbon-red:after {
    right: 0;
}

.price-box.promoted {
    border-radius: 4px 4px 0 0;
}


/*=======================================
TWEET FEED STYLES
==================================================*/

.twt-wrapper .panel-body {
    max-height: 650px;
    overflow: auto;
}

.twt-wrapper .media-list .media {
    border-bottom: 1px dashed #efefef;
    margin-bottom: 25px;
}


/*=======================================
SHOW HIDE DIV STYLES
==================================================*/

.show {
    display: block !important;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.invisible {
    visibility: hidden;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.btn-circle.btn-xl {
    width: 150px;
    height: 150px;
    padding: 10px 16px;
    font-size: 50px;
    line-height: 1.33;
    border-radius: 35px;
}

.full-width-tabs>ul.nav.nav-tabs {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.full-width-tabs>ul.nav.nav-tabs>li {
    float: none;
    display: table-cell;
}

.full-width-tabs>ul.nav.nav-tabs>li>a {
    text-align: center;
}

.full-tab-width {
    width: 100%;
}

.full-width-tabs>ul.nav.nav-tabs>li a.active {
    background: inherit;
}

.media-item {
    display: none;
}

.media-show {
    display: block;
}


/* Hover effect */

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #6ec2ee;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 50px 20px;
}

.hovereffect img {
    display: block;
    position: relative;
    max-width: none;
    width: calc(100% + 20px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.hovereffect:hover img {
    opacity: 0.4;
    filter: alpha(opacity=40);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}


/* Browser specific css for IE  */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .hovereffect h2 {
        text-transform: uppercase;
        color: #fff;
        left: 0;
        height: 60px;
        width: 100%;
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        padding: 0.5em 0;
        background-color: transparent;
    }
    .hovereffect h2:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #fff;
        content: '';
    }
    .hovereffect a,
    .hovereffect p {
        color: #fff;
        opacity: 0;
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        filter: alpha(opacity=0);
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.hovereffect h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    padding: 0.5em 0;
    background-color: transparent;
}

.hovereffect h2:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: '';
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.hovereffect:hover h2:after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.hovereffect a,
.hovereffect p {
    color: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.hovereffect:hover a,
.hovereffect:hover p {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.ribbon-container-lg {
    width: 130px;
    height: 130px;
    right: -8px;
    z-index: 100;
}

.ribbon-red-lg {
    top: 28px;
    width: 160px;
}

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
    /* Adjust as needed */
}

.label-right {
    float: right;
    align-items: flex-end;
    margin: 0em 0.2em;
}

.main-footer {
    position: fixed;
    display: inherit;
    bottom: 0;
    width: 100%;
}

.wizard-body {
    background: #f9f8f7;
}

.content-card {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #f3f3f3;
    display: table;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 4px;
}

.nickName {
    padding-top: 0;
    margin: 0;
}

.right {
    float: right;
}

.input-range__slider {
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 2rem;
    margin-left: -1rem;
    margin-top: -1.2rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 2rem;
}

.input-range__slider:active {
    transform: scale(1.3);
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
}

.input-range__slider-container {
    transition: left 0.3s ease-out;
}

.input-range__label {
    color: #aaaaaa;
    font-family: 'Helvetica Neue', san-serif;
    font-size: 1.6rem;
    transform: translateZ(0);
    white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
    bottom: -1.8rem;
    position: absolute;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--value {
    position: absolute;
    top: -3.6rem;
}

.input-range__label-container {
    left: -50%;
    position: relative;
    font-size: 14px;
}

.input-range__label--max .input-range__label-container {
    left: 0;
}

.input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.6rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
    background: #eeeeee;
}

.input-range__track--background {
    left: 0;
    margin-top: -0.3rem;
    position: absolute;
    right: 0;
    top: 50%;
}

.input-range__track--active {
    background: #006de4;
}

.input-range {
    height: 2rem;
    position: relative;
    width: 100%;
    padding: 12px;
    margin: 24px 0;
}

.content {
    padding: 24px;
}

.hole {
    padding: 12px;
}

.spacer {
    width: 125px;
    display: inline-block;
}

.clear {
    clear: both;
}

.pulse-button {
    -webkit-animation: pulse 1s 3 cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1s 3 cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1s 3 cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1s 3 cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
}

.popover {
    font-family: 'Roboto', sans-serif;
    background: rgba(255, 255, 255, 1);
    color: #5a5a5a;
    border-radius: 4px;
    border: none;
}

@-webkit-keyframes pulse {
    to {
        box-shadow: 0 0 0 5px rgba(51, 122, 183, 0.25);
    }
}

@-moz-keyframes pulse {
    to {
        box-shadow: 0 0 0 5px rgba(51, 122, 183, 0.25);
    }
}

@-ms-keyframes pulse {
    to {
        box-shadow: 0 0 0 5px rgba(51, 122, 183, 0.25);
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 5px rgba(51, 122, 183, 0.25);
    }
}

.leaderboard {
    text-align: center;
    margin: 15px 0;
}

.the-tag-list {
    max-width: 500px;
}

.tag-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.carousel-caption {
    z-index: 10;
    color: #fff;
    text-align: left;
    top: 50%;
    bottom: auto;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
    vertical-align: middle;
}

.btn-circle.btn-md {
    width: 35px;
    height: 35px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
}

.btn-white {
    background-color: #ffffff;
    color: #1396e2;
}

.btn-blue {
    background-color: #1396e2;
    color: #ffffff;
}

.btn-deal-check {
    background-color: #ffffff;
    color: green;
}

.btn-white-love {
    background-color: #ffffff;
    color: red;
}

.div-btn-right {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Gotham SSm", Helvetica Neue, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42857;
    color: #222;
    box-sizing: border-box;
    float: right !important;
    -webkit-box-align: center !important;
    display: flex !important;
    align-items: center !important;
}

.div-btn-completed {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 13px;
    line-height: 1.42857;
    color: #222;
    cursor: pointer;
    box-sizing: border-box;
}

.btn-completed {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    overflow: visible;
    text-transform: none;
    font-family: inherit;
    user-select: none;
    border: solid 2px transparent;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    transition: all .15s;
    padding: 7px 18px;
    font-size: 13px;
    line-height: 13px;
    border-radius: 2px;
    padding-bottom: 6px;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: #a0a0a0 !important;
    border-color: #e0e0e0;
    background: #e0e0e0 !important;
    text-shadow: none;
    text-decoration: none;
    outline: none !important;
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none !important;
    min-width: 120px;
    padding-right: 0;
    padding-left: 0;
}

.badge-bubble {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Gotham SSm", Helvetica Neue, Arial, sans-serif;
    --display-navbar-collapse: block;
    -webkit-box-direction: normal;
    list-style: none;
    text-transform: none;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    color: #fff;
    background-color: #1396e2;
    padding: 5px 7px;
    border-radius: 2px;
    font-weight: 400;
    vertical-align: middle;
    margin: -4px 0 -4px 5px;
    position: relative;
    -webkit-font-smoothing: auto;
    margin-left: auto;
    border-radius: 50%;
}

.badge-comment-bubble {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Gotham SSm", Helvetica Neue, Arial, sans-serif;
    --display-navbar-collapse: block;
    -webkit-box-direction: normal;
    list-style: none;
    text-transform: none;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    color: #fff;
    background-color: #1396e2;
    padding: 5px 7px;
    border-radius: 2px;
    font-weight: 400;
    vertical-align: middle;
    margin: -4px 0 -4px 5px;
    position: relative;
    -webkit-font-smoothing: auto;
    margin-left: auto;
}

.badge-comment-bubble:before,
.badge-ellipsis:before {
    transform: rotate(-90deg) scale(1.3);
    content: " ";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    bottom: -2px;
    left: 5px;
    color: #1396e2;
}

.bucket-tabs {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 13px;
    line-height: 1.42857;
    color: #222;
    box-sizing: border-box;
}

.bucket-tabs:before {
    box-sizing: border-box;
}

.bucket-tabs:after {
    box-sizing: border-box;
}

.air-cards {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 13px;
    line-height: 1.42857;
    color: #222;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    margin: 30px -30px;
    box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
    padding: 0 !important;
}

.buckets-container {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 13px;
    line-height: 1.42857;
    color: #222;
    box-sizing: border-box;
    margin-top: 0 !important;
}

.bucket-breadcrumb {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    margin: 0 !important;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    position: relative;
    height: 100%;
    margin-left: -2px !important;
    margin-right: -2px !important;
    width: auto;
    color: #656565;
    font-size: 12px;
}

.bucket-a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 12px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    font-weight: 500;
    -webkit-flex: 1;
    height: 60px;
    outline: none;
    color: #222;
    margin-right: -2px;
    display: flex;
    position: relative;
    z-index: 1;
}

.bucket-a:before {
    top: 0;
    transform: skewX(25deg);
    border-bottom-width: 0;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: transparent #e0e0e0;
    -webkit-border-radius: 1px;
}

.bucket-a:first-child>div:before {
    left: 0;
    border-right-width: 0;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #fff;
    border-width: 0px;
    border-style: solid;
    border-color: transparent #e0e0e0;
}

.bucket-a:after {
    top: 50%;
    transform: skewX(-25deg);
    border-top-width: 0;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: transparent #e0e0e0;
    -webkit-border-radius: 1px;
}

.bucket-a-inner {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Gotham SSm", Helvetica Neue, Arial, sans-serif;
    line-height: 1.42857;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    box-sizing: border-box;
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.bucket-a-inner-copy {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: #1396e2;
    box-sizing: border-box;
    display: inline !important;
    text-align: center;
}

.bucket-a .mobile,
.bucket-breadcrumb .mobile {
    display: none;
}

.bucket-breadcrumb .desktop {
    display: inline-block;
}

.bucket-a-active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 12px;
    text-transform: uppercase;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: 500;
    -webkit-flex: 1;
    height: 60px;
    outline: none;
    margin-right: -2px;
    display: flex;
    position: relative;
    z-index: 3;
    text-decoration: none !important;
    color: #fff;
}

.bucket-breadcrumb .bucket-a-active:before {
    top: 0;
    transform: skewX(25deg);
    background-color: #1396e2;
    border: none;
    box-sizing: content-box;
    border-bottom: 1px solid #1396e2;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    -webkit-border-radius: 1px;
}

.bucket-breadcrumb .bucket-a-active:after {
    background-color: #1396e2;
    border: none;
    top: 50%;
    transform: skewX(-25deg);
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    -webkit-border-radius: 1px;
}

.bucket-a-active:after {
    background-color: #1396e2;
    border: none;
    top: 50%;
    transform: skewX(-25deg);
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    -webkit-border-radius: 1px;
    box-sizing: border-box;
}

.bucket-a-active-inner {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bucket-a-active-inner-first {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bucket-a-active-inner-first:before {
    background-color: #1396e2;
    border-color: #1396e2;
    left: 0;
    border-right-width: 0;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    border-width: 2px;
    border-style: solid;
}

.bucket-a-active-inner-last {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bucket-a-active-inner-last:before {
    background-color: #1396e2;
    border-color: #1396e2;
    right: 2px;
    border-left-width: 0;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    border-width: 2px;
    border-style: solid;
}

.bucket-a-active-inner-copy {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 1.42857;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    display: block !important;
    text-align: center;
}

.bucket-a-active-inner-subhead {
    line-height: 1.42857;
    list-style: none;
    font-weight: 500;
    box-sizing: border-box;
    display: block !important;
    font-size: 12px;
    text-transform: capitalize;
    color: #ffffff;
}

.bucket-a-inner-subhead {
    line-height: 1.42857;
    list-style: none;
    font-weight: 500;
    box-sizing: border-box;
    display: block !important;
    font-size: 12px;
    text-transform: capitalize;
}

.carousel .teaserHeadline {
    font-size: 40px;
    text-align: center;
    border-bottom: 0px;
    margin-bottom: 5px;
}

.highlight-spot {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: crimson;
}

.highlight-spot:hover {
    cursor: pointer;
}

.highlight-spot:after {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 30px;
    height: 30px;
    content: '';
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: .0;
    border: 3px solid crimson;
    -webkit-border-radius: 30px;
}

.top {
    top: 0;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.center-y {
    top: 50%;
    transform: translateY(-50%);
}

.center-x {
    left: 0;
    right: 0;
    margin: 0 auto;
}

@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(.1, .1);
        opacity: .0;
    }
    50% {
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: .0;
    }
}

.continuityCounter {
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    font-size: 13px;
    line-height: 1.38463;
    font-weight: 400;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(var(--sk_foreground_low, 29, 28, 29), .13);
}

.continuityWorkflowContainer {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
}

.continuityWorkflowCard {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    display: flex;
    border-bottom: 1px solid rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1);
    flex-direction: row;
    padding: 24px;
}

.continuityWorkflowCardItem {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 66%;
    padding-right: 20px;
}

.continuityWorkflowCardNumber {
    font-size: 15px;
    line-height: 1.46668;
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    background-color: #1396e2;
    border-radius: 20px;
    color: #fff;
    height: 40px;
    width: 40px;
    margin-bottom: 1rem;
    margin-right: 16px;
    padding: 8px 16px;
    font-weight: 700;
}

.continuityWorkflowCardNumberComplete {
    font-size: 15px;
    line-height: 1.46668;
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    border-radius: 20px;
    color: #fff;
    height: 40px;
    width: 40px;
    margin-bottom: 1rem;
    margin-right: 16px;
    font-weight: 700;
    padding: 8px 10px;
    background-color: rgba(var(--sk_foreground_high_solid, 134, 134, 134), 1);
}

.continuityWorkflowCardBody {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    width: 100%;
    margin-bottom: 16px;
    align-self: center;
}

.continuityWorkflowCardItemRight {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 34%;
}

.continuityWorkflowFullWidth {
    font-size: 15px;
    line-height: 1.46668;
    font-weight: 400;
    color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    font-family: Slack-Lato, appleLogo, sans-serif;
    box-sizing: inherit;
    width: 100%;
    max-width: 100%;
}

.continuityWorkflowButton {
    font: inherit;
    overflow: visible;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    align-items: center;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    font-weight: 500;
    font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif;
    border-radius: 4px;
    text-transform: uppercase;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    width: 100%;
    color: white;
    background: rgb(19, 150, 226);
    margin-top: 5px;
}

.continuityWorkflowButtonToggle {
    font: inherit;
    overflow: visible;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    align-items: center;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    font-weight: 500;
    font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif;
    border-radius: 4px;
    text-transform: uppercase;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    width: 100%;
    color: rgb(19, 150, 226);
    background: white;
    margin-top: 5px;
}

.continuity_upload {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased !important;
    line-height: 1.42857143;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    text-rendering: optimizeLegibility;
    box-sizing: inherit;
    flex: 1;
    color: #bdbdbd;
    display: flex;
    padding: 20px;
    outline: none;
    transition: border .24s ease-in-out;
    align-items: center;
    border-width: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    border-radius: 2px;
    flex-direction: column;
    background-color: #fafafa;
}

.continuity_upload_input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased !important;
    box-sizing: inherit;
    margin: 0;
    font: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: none;
}

.continuity_video_button {
    --sidebar-color: #4d394b;
    --sidebar-highlight-color: #1264a3;
    --attachment-color: #1d1d1d;
    --more-count: '+1';
    --article-theme-secondary: #fff;
    -webkit-font-smoothing: antialiased;
    --article-theme-primary: #ecb22e;
    --theme-text: #1d1d1d;
    --theme-bg-large: #ecb22e;
    --theme-stat-color: #1d1d1d;
    --theme-border-color: #ecb22e;
    --theme-bullet-color: #ecb22e;
    --theme-num-bg: #ecb22e;
    --theme-num-text-color: #1d1d1d;
    --theme-ad-background: #ecb22e;
    --theme-ad-text: #1d1d1d;
    --theme-ad-border: transparent;
    --theme-ad-arrow: #1d1d1d;
    box-sizing: inherit;
    color: inherit;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: none;
    background: 0 0;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    text-align: center;
    padding: 0;
    border-bottom: none;
    justify-content: center;
    margin: auto;
    position: relative;
    margin-top: 1rem;
}

.continuity_button_image {
    --sidebar-color: #4d394b;
    --sidebar-highlight-color: #1264a3;
    --attachment-color: #1d1d1d;
    --more-count: '+1';
    --article-theme-secondary: #fff;
    -webkit-font-smoothing: antialiased;
    --article-theme-primary: #ecb22e;
    --theme-text: #1d1d1d;
    --theme-bg-large: #ecb22e;
    --theme-stat-color: #1d1d1d;
    --theme-border-color: #ecb22e;
    --theme-bullet-color: #ecb22e;
    --theme-num-bg: #ecb22e;
    --theme-num-text-color: #1d1d1d;
    --theme-ad-background: #ecb22e;
    --theme-ad-text: #1d1d1d;
    --theme-ad-border: transparent;
    --theme-ad-arrow: #1d1d1d;
    color: inherit;
    font: inherit;
    text-transform: none;
    text-align: center;
    box-sizing: inherit;
    border: 0;
    max-width: 100%;
    height: auto;
    min-width: 100%;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1) !important;
}

.deal-icon {
    color: #1396e2 !important;
}

.deal-invert-button {
    background: #ffffff;
    color: rgb(19, 150, 226);
}

.borderd-content {
    border: 1px solid #5a5a5a;
    border-radius: 4px;
    min-height: 60px;
    margin-top: 20px;
    position: relative;
}

.borderd-content .title {
    margin: -10px 0 0 10px;
    background: rgb(249, 248, 247);
    padding: 3px;
    display: inline-block;
    position: absolute;
}

.borderd-content .content {
    padding: 10px;
    float: left;
    width: 48%;
}

.borderd-content-search {
    border: 1px solid #5a5a5a;
    border-radius: 4px;
    min-height: 80px;
    margin-top: 20px;
    position: relative;
}

.borderd-content-search .title {
    margin: -10px 0 0 10px;
    background: rgb(19, 150, 226);
    padding: 3px;
    position: absolute;
    max-width: 70%;
    max-width: 70%;
}

.borderd-content-search .content {
    padding: 10px;
    float: left;
    width: 49%;
}

.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

.continuity_popover {
    color: #5489B6;
}

.jumbotron_education {
    background-color: rgb(19, 150, 226);
}

.cta_banner {
    background-color: rgb(19, 150, 226);
}

.jumbotron h1 {
    color: #ffffff;
}

.custom_education_banner {
    color: #ffffff;
}

.custom_education_banner_font {
    color: #ffffff;
}

@keyframes shimmer {
0% {
    opacity: 0.2;
}
50% {
    opacity: 1;
}
100% {
    opacity: 0.2;
}
}

.icon_shimmer {
    animation: pulse 1s 1s;
}

/* Main container styles */
.top-nav {
    padding: 10px;
    position: relative;
  }
  
  .nav.pull-right.top-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  /* Dropdown toggle link */
  .nav a.dropdown-toggle {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    display: inline-block;
  }
  
  /* Username */
  .username {
    margin-left: 5px;
  }
  
  /* Caret */
  b.caret {
    margin-left: 5px;
    border-top: 5px solid white;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    display: inline-block;
  }
  
  /* Dropdown Menu hidden by default */
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    min-width: 150px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    list-style-type: none;
    padding: 10px 0;
    margin: 0;
    z-index: 1000;
  }
  
  /* Dropdown menu items */
  .dropdown-menu li {
    padding: 10px;
    width: 100%;
  }
  
  .dropdown-menu li a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 0 15px;
  }
  
  .dropdown-menu li:hover {
    background-color: #f2f2f2;
  }
  
  /* Show the dropdown when hovering on the parent li */
  .nav li:hover .dropdown-menu {
    display: block;
  }
  